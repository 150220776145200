import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Card,
  Image,
  Grid,
  Spin,
  notification,
  Space,
} from "antd";
import { CrownOutlined, SendOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { fallbackImage } from "@assets";
import { awardsAPI, usersAPI } from "@api";
import { useQuery } from "@tanstack/react-query";
import { ClaimAwardModal } from "components/ClaimAwardModal/ClaimAwardModal";
import {
  useHasPermission,
} from "hooks/useHasPermission";
import { Roles } from "@router/Protected";
import './AwardsClaim.styles.css';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

export const ClaimStatusTypes = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
}

const AwardsClaimPage = () => {
  const [claimModalParams, setClaimModalParams] = useState({
    award: null,
    isGiveable: false,
    showModal: false,
  });
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const { xs } = useBreakpoint();
  const hasPermission = useHasPermission();

  const { isLoading: awardsLoading, data: awards } = useQuery({
    queryKey: ["awards"],
    queryFn: () => awardsAPI.getAwards({ available: true }).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { data: totalExistingAwards } = useQuery({
    queryKey: ["totalExistingAwards"],
    queryFn: () => usersAPI.getMyClaims({ status: ClaimStatusTypes.PENDING }).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });


  return (
    <>
      <Spin tip="Loading" spinning={awardsLoading}>
        <Row justify="center" align={"top"} gutter={20}>
          <Col span={22}>
            <Typography.Title align="left" level={2} style={{ marginTop: 0 }}>
              {t("navbar.awards")}
            </Typography.Title>
          </Col>
          <Col span={22}>
            <Row
              justify={{ xs: "center", sm: "center", lg: "left" }}
              align={"stretch"}
              gutter={[20, 20]}
              height="100%"
            >
              {awards?.data.map((award) => {
                return (
                  <Col
                    key={award.id}
                    xs={24}
                    sm={12}
                    md={10}
                    lg={8}
                    xxl={5}
                    style={{ display: "flex" }}
                  >
                    <Card
                      className="award-card"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      bodyStyle={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1 1 100%",
                      }}
                      cover={
                        <Image
                          src={award.imageUrl}
                          alt={award.name}
                          fallback={fallbackImage}
                          style={{
                            height: "200px",
                            objectFit: "contain",
                            padding: 10,
                          }}
                          height={200}
                          preview={false}
                        />
                      }
                      actions={
                        [
                          <Space key={award.id} style={{ margin: "0px 12px" }} size={8} onClick={() => setClaimModalParams({ showModal: true, award })}>
                            <CrownOutlined
                              style={{
                                fontSize: "24px",
                                color: "#2966c6",
                              }}
                            />
                            <Text style={{ color: "#2966c6" }}>
                              {t("awardsClaim.request")}
                            </Text>
                          </Space>,
                          ...hasPermission([Roles.SUPERADMIN])
                            ? [
                              <Space key={award.id} style={{ margin: "0px 12px" }} size={8} onClick={() => setClaimModalParams({ showModal: true, award, isGiveable: true })}>
                                <SendOutlined
                                  style={{
                                    fontSize: "24px",
                                    color: "#2966c6",
                                  }}
                                />
                                <Text style={{ color: "#2966c6" }}>
                                  {t("awardsClaim.give")}
                                </Text>
                              </Space>
                            ] : []
                        ]
                      }
                    >
                      <Title level={5} style={{ margin: 0 }}>
                        {award.name}
                      </Title>
                      <Text type="secondary" style={{ marginTop: 6 }}>
                        {award.description}
                      </Text>
                      <Space
                        direction="vertical"
                        style={{
                          width: "100%",
                          marginTop: "auto",
                          paddingTop: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: xs ? "20px" : "16px",
                            fontWeight: 400,
                          }}
                          type="secondary"
                        >
                          {t("awardsClaim.youGet")}
                        </Text>
                        <Space
                          align="center"
                          wrap={false}
                          style={{
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: xs ? "22px" : "18px",
                              fontWeight: 600,
                              color: "#2966c6",
                            }}
                          >{`${award.amount} ${t("points")}`}</Text>
                        </Space>
                      </Space>
                    </Card>
                  </Col>
                );
              })}
              <ClaimAwardModal
                award={claimModalParams.award}
                open={claimModalParams.showModal}
                onCancel={() => setClaimModalParams({})}
                setShowAwardModal={setClaimModalParams}
                giveable={claimModalParams.isGiveable}
                totalExistingAwards={totalExistingAwards}
              />
            </Row>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default AwardsClaimPage;
