import instance from "./api";

export default {
  getSettings: async () => {
    return instance.get(`/settings`);
  },
  saveSettings: async (data) => {
    return instance.put(`/settings`, data);
  },
};
