import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import { ForbiddenScreen } from "@pages";
import { REACT_APP_KEYCLOAK_CLIENT_ID } from 'hooks/useHasPermission';

export const RolePolicy = {
  MATCH_ANY: "MATCH_ANY",
  MATCH_ALL: "MATCH_ALL",
};

export const Roles = {
  SUPERADMIN: "superadmin",
};

export const Protected = ({
  roles,
  children,
  policy = RolePolicy.MATCH_ALL,
  route = false,
}) => {
  const {
    keycloak: { token, authenticated, login },
  } = useKeycloak();
  if (!authenticated || !token) login();

  const payload = decodeJwt(token);
  if (!roles || roles.length === 0) return children;

  if (!payload.resource_access?.[REACT_APP_KEYCLOAK_CLIENT_ID]?.roles) return route ? <ForbiddenScreen /> : <></>;

  let hasPermission = false;
  if (policy === RolePolicy.MATCH_ANY)
    hasPermission = roles?.some((role) =>
      payload.resource_access?.[REACT_APP_KEYCLOAK_CLIENT_ID].roles?.includes(role)
    );
  if (policy === RolePolicy.MATCH_ALL)
    hasPermission = roles?.every((role) =>
      payload.resource_access?.[REACT_APP_KEYCLOAK_CLIENT_ID].roles?.includes(role)
    );

  if (hasPermission) return children;
  return route ? <ForbiddenScreen /> : <></>;
};

const make = (array) => {
  return array.map((item) => {
    const obj = {};
    obj[`${item}_CREATE`] = `${item.toLowerCase()}.create`;
    obj[`${item}_GET`] = `${item.toLowerCase()}.get`;
    obj[`${item}_UPDATE`] = `${item.toLowerCase()}.update`;
    obj[`${item}_DELETE`] = `${item.toLowerCase()}.delete`;
    return obj;
  });
};

make(["COUNTRY", "POST", "CURRENCY", "REDEMPTION", "TAG"]);
