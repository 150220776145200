import instance from "./api";

export default {
  getPosts: async (params) => {
    return instance.get("/posts", { params });
  },
  getPost: async (id) => {
    return instance.get(`/posts/${id}`);
  },
  createPost: async (data) => {
    return instance.post("/posts", data);
  },
  getLikes: async (id) => {
    return instance.get(`/posts/${id}/likes`);
  },
  togglePostLike: async (id, data) => {
    return instance.post(`/posts/${id}/like`, data);
  },
  getComments: async (id) => {
    return instance.get(`/posts/${id}/comments`);
  },
  createComment: async (id, data) => {
    return instance.post(`/posts/${id}/comments`, data);
  },
};
