import React, { useEffect } from "react";
import {
  Col,
  Row,
  Typography,
  Card,
  Form,
  Switch,
  InputNumber,
  Button,
  Spin,
  Input,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { settingsAPI } from "@api";

const SettingsPage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const queryClient = useQueryClient();

  const { data: settings, isLoading: settingsLoading } = useQuery({
    queryKey: ["settings"],
    queryFn: () => settingsAPI.getSettings().then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate } = useMutation({
    mutationFn: (values) => {
      return settingsAPI.saveSettings(
        settings.map((setting) => {
          return {
            id: setting.id,
            value: values[setting.parameter],
          };
        })
      );
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["settings"], () => data);
      notification.success({
        message: "Success",
        description: t("settings.saved"),
      });
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  useEffect(() => {
    if (settings)
      form.setFieldsValue({
        ...settings,
        slackNotificationsActive: settings.find(
          (setting) => setting.parameter === "slackNotificationsActive"
        ).value,
        monthlyAllowanceBalance: settings.find(
          (setting) => setting.parameter === "monthlyAllowanceBalance"
        ).value,
      });
  }, [settings]);

  return (
    <>
      <Spin
        tip="Loading"
        spinning={settingsLoading}
        style={{ width: "100%", textAlign: "center", padding: "60px 0" }}
      >
        <Row justify="center" align="middle" style={{ padding: 10, background: "white" }}>
          <Col span={22}>
            <Typography.Title align="left" level={2} style={{ marginTop: 0 }}>
              {t("navbar.settings")}
            </Typography.Title>
          </Col>
          <Col span={22}>
            <Card>
              <Form form={form}>
                <Form.Item
                  label={t("settings.slackNotificationsActive")}
                  name="slackNotificationsActive"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  label={t("settings.monthlyAllowanceBalance")}
                  name="monthlyAllowanceBalance"
                >
                  <InputNumber style={{ width: 150 }} min={0} />
                </Form.Item>
                <Button
                  onClick={() => mutate(form.getFieldsValue())}
                  type={"primary"}
                >
                  {t("settings.saveChanges")}
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default SettingsPage;
