import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Card,
  Avatar,
  Modal,
  Result,
  Image,
  Grid,
  Spin,
  notification,
  Space,
  Tooltip,
  Select,
  Statistic,
  Alert,
  Pagination,
  Input,
} from "antd";
import {
  GiftOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { BalanceCard } from "@components";
import { fallbackImage } from "@assets";
import { prizesAPI, usersAPI } from "@api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  useHasPermission,
} from "hooks/useHasPermission";
import { Roles } from "@router/Protected";
import { useDebounce } from "@hooks";
import { StockTypes } from "components/PostCard/PostCard";
import { OrderByDirection } from "@constants";

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;
const { success } = Modal;
const { Search } = Input;

export const RedemptionStatusTypes = {
  PENDING: 'PENDING',
  ON_DELIVERY: 'ON_DELIVERY',
  ON_HOLD: 'ON_HOLD',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
}

const OrderByField = {
  PRICE: 'price'
}

const MarketplacePage = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    orderByField: null,
    orderByDirection: null,
    available: true
  });
  const [userSearch, setUserSearch] = useState("");
  const [searchPrize, setSearchPrize] = useState("");
  const debouncedSearchPrize = useDebounce(searchPrize, 500);
  const debouncedUserSearch = useDebounce(userSearch, 300);
  const queryClient = useQueryClient();
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const { xs, sm, md } = useBreakpoint();
  const hasPermission = useHasPermission();

  const [searchParams, setSearchParams] = useSearchParams();
  const prizeId = searchParams.get('prizeId');

  const [recipientId, setRecipientId] = useState(null);
  const [claimModalParams, setClaimModalParams] = useState({
    prize: null,
    isExchange: false,
    showModal: false,
  });
  const prizeFound = claimModalParams.prize;

  const { isLoading: prizesLoading, data: prizes } = useQuery({
    queryKey: ["prizes", { ...params, search: debouncedSearchPrize }],
    queryFn: () => prizesAPI.getPrizes({ ...params, search: debouncedSearchPrize }).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
    onSuccess: prizes => {
      if (prizeId) {
        const prize = prizes?.find(({ id }) => id === prizeId);
        if (prize && (
          (prize.stockType === StockTypes.STOCK && prize.stock > 0)
          || prize.stockType === StockTypes.DEMAND
        )) setClaimModalParams({ showModal: true, prize });
      }
    },
    refetchOnWindowFocus: false
  });

  const { mutate: redeemPrize } = useMutation({
    mutationFn: ({ prize, recipientId }) => {
      if (recipientId) {
        return prizesAPI
          .exchangePrize({ prizeId: prize.id, recipientId })
          .then(({ data }) => data)
      } else {
        return prizesAPI
          .redeemPrize({ prizeId: prize.id })
          .then(({ data }) => data)
      }
    },
    onSuccess: (data, { prize, recipientId }) => {
      !recipientId && queryClient.setQueryData(["wallet"], (prev) => ({
        ...prev,
        rewardBalance: prev.rewardBalance - prize.price,
      }));
      queryClient.invalidateQueries(["prizes-basic"]);
      onCloseClaimModal();
      success({
        icon: null,
        maskClosable: true,
        onOk: onCloseClaimModal,
        content: (
          <>
            <Result
              status="success"
              title={`${t("marketplace.redemption.success")} ${prize.name}`}
              subTitle={t("marketplace.redemption.successDescription")}
              extra={
                <Title align="center" level={3} style={{ margin: 0 }}>
                  {`${t("status.status")}: ${t(`status.${data.status}`)}`}
                </Title>
              }
            />
          </>
        ),
        centered: true,
      });
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["usersOptions", debouncedUserSearch],
    queryFn: () =>
      usersAPI
        .searchUsers({
          search: debouncedUserSearch,
          limit: 10,
        })
        .then(({ data }) =>
          data.map((user) => ({
            label: user.fullName,
            value: user.id,
          }))
        ),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { data: totalExistingRedemptions } = useQuery({
    queryKey: ["totalExistingRedemptions"],
    queryFn: () => usersAPI.getMyRedemptions({ status: RedemptionStatusTypes.PENDING }).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });
  const existingRedemptions = totalExistingRedemptions?.filter(existingRedemption => existingRedemption.prize.id === prizeFound?.id).length;
  
  const { data: recipient } = useQuery({
    queryKey: ["recipient", recipientId],
    queryFn: () => usersAPI.getUser(recipientId).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
    enabled: !!recipientId
  });

  const onCloseClaimModal = () => {
    setClaimModalParams({});
    setSearchParams({});
    setRecipientId(null);
  }

  return (
    <>
      <Spin tip="Loading" spinning={prizesLoading}>
        <Row justify="center" align={"top"} gutter={20}>
          <Col span={22}>
            <Title align="left" level={2} style={{ marginTop: 0 }}>
              {t("navbar.marketplace")}
            </Title>
          </Col>
          <Col span={22} style={{ display: "flex", flexDirection: sm ? "row" : "column", justifyContent: "space-between", marginBottom: 20 }}>
            <BalanceCard />
            <Space direction={md ? "horizontal" : "vertical"} style={{ marginTop: sm ? 0 : 8 }}>
              <Space>
                <Text>{t("marketplace.form.orderby.title")}</Text>
                <Select
                  onChange={value => setParams({
                    ...params,
                    orderByField: OrderByField.PRICE,
                    orderByDirection: value
                  })}
                  style={{ width: 150 }}
                  placeholder={t("marketplace.form.orderby.placeholder")}
                  options={[
                    {
                      value: OrderByDirection.ascend,
                      label: t("marketplace.form.orderby.lowerPoints"),
                    },
                    {
                      value: OrderByDirection.descend,
                      label: t("marketplace.form.orderby.higherPoints"),
                    },
                  ]}
                  allowClear
                />
              </Space>
              <Search
                onChange={(e) => setSearchPrize(e.target.value)}
                style={{ width: "100%", maxWidth: "300px" }}
                allowClear
                placeholder={t("marketplace.form.search")}
              />
            </Space>
          </Col>
          <Col span={22}>
            <Row
              justify="center"
              align="stretch"
              gutter={[20, 20]}
              height="100%"
            >
              {
                prizes?.data.map((prize) => (
                  <Col
                    key={prize.id}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xxl={4}
                    style={{ display: "flex" }}
                  >
                    <Card
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      bodyStyle={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1 1 100%",
                      }}
                      cover={
                        <Image
                          src={prize.imageUrl}
                          alt={prize.name}
                          fallback={fallbackImage}
                          style={{
                            height: "200px",
                            objectFit: "contain",
                            padding: 10,
                          }}
                          height={200}
                          preview={false}
                        />
                      }
                      actions={hasPermission([Roles.SUPERADMIN]) ?
                        prize.stockAmount === 0 && prize.stockType === StockTypes.STOCK ? ([
                          <Text key="nostock" style={{ cursor: "default", color: "gray", display: "block", width: "100%" }}>
                            {t("marketplace.noStock")}
                          </Text>
                        ]) : ([
                          <Tooltip key="redeem" title={t("marketplace.actions.redeem.tooltip")} placement="bottom">
                            <Space
                              onClick={() => setClaimModalParams({ showModal: true, prize })}
                            >
                              <ShoppingCartOutlined
                                style={{ color: "#2966c6", fontSize: "24px" }}
                              />
                              <Text style={{ color: "#2966c6" }}>{t("marketplace.actions.redeem.button")}</Text>
                            </Space>
                          </Tooltip>,
                          <Tooltip key="exchange" title={t("marketplace.actions.exchange.tooltip")} placement="bottom">
                            <Space
                              onClick={() => setClaimModalParams({ showModal: true, isExchange: true, prize })}
                            >
                              <GiftOutlined style={{ color: "#2966c6", fontSize: "24px" }} />
                              <Text style={{ color: "#2966c6" }}>{t("marketplace.actions.exchange.button")}</Text>
                            </Space>
                          </Tooltip>
                        ])
                        : [
                          prize.stockAmount === 0 && prize.stockType === StockTypes.STOCK ? (
                            <Text style={{ cursor: "default", color: "gray", display: "block", width: "100%" }}>
                              {t("marketplace.noStock")}
                            </Text>
                          ) : (
                            <Tooltip key="redeem" title={t("marketplace.actions.redeem.tooltip")} placement="bottom">
                              <Space
                                onClick={() => setClaimModalParams({ showModal: true, prize })}
                              >
                                <ShoppingCartOutlined
                                  style={{ color: "#2966c6", fontSize: "24px" }}
                                />
                                <Text style={{ color: "#2966c6" }}>{t("marketplace.actions.redeem.button")}</Text>
                              </Space>
                            </Tooltip>
                          ),
                        ]}
                    >
                      <Title level={5} style={{ margin: 0 }}>
                        {prize.name}
                      </Title>
                      <Text type="secondary" style={{ marginTop: 6 }}>
                        {prize.description}
                      </Text>
                      <Space
                        align="center"
                        wrap={false}
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "auto",
                          paddingTop: 20,
                        }}
                      >
                        <Avatar
                          size="default"
                          icon={
                            <TagOutlined
                              key="tag"
                              style={{ color: "#2966c6" }}
                            />
                          }
                          style={{ background: "#edeffb" }}
                        />
                        <Text
                          style={{
                            fontSize: xs ? "22px" : "18px",
                            fontWeight: 600,
                            color: "#2966c6",
                          }}
                        >{`${prize.price} ${t("marketplace.points")}`}</Text>
                      </Space>
                    </Card>
                  </Col>
                ))
              }
            </Row>
          </Col>
          {
            (prizes?.count > 0) ? (
              <Pagination
                total={prizes?.count}
                current={params.page}
                pageSize={params.limit}
                onChange={(page, pageSize) => {
                  setParams({
                    ...params,
                    page,
                    limit: pageSize
                  })
                }}
                style={{ marginTop: 20 }}
                showSizeChanger
              />
            ) : (
              <Alert message={t("marketplace.form.notfound", { search: debouncedSearchPrize })} type="info" style={{ display: "block", margin: "0 auto" }} />
            )
          }
        </Row>

        {
          claimModalParams.showModal && (
            <Modal
              open={claimModalParams.showModal}
              centered
              okText={t("marketplace.confirm.ok")}
              okType="default"
              onOk={() => redeemPrize({ prize: prizeFound, recipientId })}
              onCancel={onCloseClaimModal}
              okButtonProps={{
                disabled: claimModalParams.isExchange && (!recipient || !(recipient.wallet.rewardBalance >= prizeFound.price))
              }}
              closeIcon={false}
              title={t("marketplace.confirm.title", { award: prizeFound.name })}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Text>{claimModalParams.isExchange ? t("marketplace.confirm.content.exchange") : t("marketplace.confirm.content.redeem")}</Text>
                <Space direction="vertical" style={{ textAlign: "center", width: "100%" }}>
                  <Image
                    src={prizeFound.imageUrl}
                    height={250}
                  />
                  <Title level={4} style={{ textAlign: "center", margin: 0 }}>
                    {prizeFound?.name}
                  </Title>
                  <Space>
                    <Avatar
                      size="default"
                      icon={
                        <TagOutlined
                          key="tag"
                          style={{ color: "#2966c6" }}
                        />
                      }
                      style={{ background: "#edeffb" }}
                    />
                    <Text
                      style={{
                        fontSize: xs ? "22px" : "18px",
                        fontWeight: 600,
                        color: "#2966c6",
                      }}
                    >{`${prizeFound.price} ${t("marketplace.points")}`}</Text>
                  </Space>
                  {(!claimModalParams.isExchange && existingRedemptions > 0) && (
                    <Alert message={t("marketplace.confirm.content.existingRedemption", { count: existingRedemptions, existingRedemptions })} type="warning" showIcon />
                  )}
                </Space>
                {
                  claimModalParams.isExchange && (
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                        border: "0.5px solid #aeaeae",
                        borderRadius: 6,
                      }}
                      placeholder={t("marketplace.confirm.form.placeholder")}
                      optionFilterProp="children"
                      onSearch={setUserSearch}
                      onSelect={setRecipientId}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={users}
                      allowClear
                      notFoundContent={
                        isFetchingUsers ? <Spin size="small" /> : null
                      }
                      bordered={false}
                    />
                  )
                }
                {
                  recipient && (
                    <Space align="center" size={8} style={{ "flexWrap": "wrap", "justifyContent": "center" }}>
                      <Avatar
                        size="large"
                        icon={<ShoppingOutlined />}
                        style={{ color: "#2966c6", background: "#eef5ff" }}
                      />
                      <Statistic
                        title=<Text style={{ color: "#000", fontSize: 16, fontWeight: 600 }}>
                          {t("marketplace.availableSpendingBalance")}
                        </Text>
                        valueStyle={{
                          color: "#2966c6",
                          fontSize: "16px",
                          fontWeight: 600
                        }}
                        value={recipient.wallet.rewardBalance}
                        suffix={t("points")}
                      />
                    </Space>
                  )
                }
              </Space>
            </Modal>
          )
        }
      </Spin>
    </>
  );
};

export default MarketplacePage;
