import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { AppLayout } from "@layout/Layout";
import { NotFoundPage } from "@pages";
import { Protected, Roles, RolePolicy } from "./Protected";

const HomePage = lazy(() => import("@pages/home/Home"));
const UsersPage = lazy(() => import("@pages/users/Users"));
const MarketplacePage = lazy(() => import("@pages/marketplace/Marketplace"));
const PrizesPage = lazy(() => import("@pages/prizes/Prizes"));
const MyRedeemsPage = lazy(() => import("@pages/my-redeems/MyRedeems"));
const RedeemsPage = lazy(() => import("@pages/redeems/Redeems"));
const LeaderboardPage = lazy(() => import("@pages/leaderboard/Leaderboard"));
const MyWalletPage = lazy(() => import("@pages/my-wallet/MyWallet"));
const MyRewardsPage = lazy(() => import("@pages/my-rewards/MyRewards"));
const MyClaimsPage = lazy(() => import("@pages/my-claims/MyClaims"));
const ClaimsPage = lazy(() => import("@pages/claims/Claims"));
const AwardsPage = lazy(() => import("@pages/awards/Awards"));
const SettingsPage = lazy(() => import("@pages/settings/Settings"));
const ProfilePage = lazy(() => import("@pages/profile/Profile"));
const AwardsClaimPage = lazy(() => import("@pages/awards-claim/AwardsClaim"));
const CategoriesPage = lazy(() => import("@pages/categories/Categories"));
const DashboardPage = lazy(() => import("@pages/dashboard/Dashboard"))

const routesArray = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/users",
    element: (
      <Protected route roles={[Roles.SUPERADMIN]} policy={RolePolicy.MATCH_ANY}>
        <UsersPage />
      </Protected>
    ),
  },
  {
    path: "/marketplace",
    element: <MarketplacePage />,
  },
  {
    path: "/prizes",
    element: (
      <Protected route roles={[Roles.SUPERADMIN]} policy={RolePolicy.MATCH_ANY}>
        <PrizesPage />
      </Protected>
    ),
  },
  {
    path: "/myClaims",
    element: <MyClaimsPage />,
  },
  {
    path: "/claims",
    element: (
      <Protected route roles={[Roles.SUPERADMIN]} policy={RolePolicy.MATCH_ANY}>
        <ClaimsPage />
      </Protected>
    ),
  },
  {
    path: "/awards",
    element: (
      <Protected route roles={[Roles.SUPERADMIN]} policy={RolePolicy.MATCH_ANY}>
        <AwardsPage />
      </Protected>
    ),
  },
  {
    path: "/myRedeems",
    element: <MyRedeemsPage />,
  },
  {
    path: "/redeems",
    element: (
      <Protected route roles={[Roles.SUPERADMIN]} policy={RolePolicy.MATCH_ANY}>
        <RedeemsPage />
      </Protected>
    ),
  },
  {
    path: "/settings",
    element: (
      <Protected route roles={[Roles.SUPERADMIN]} policy={RolePolicy.MATCH_ANY}>
        <SettingsPage />
      </Protected>
    ),
  },
  {
    path: "/leaderboard",
    element: <LeaderboardPage />,
  },
  {
    path: "/myRewards",
    element: <MyRewardsPage />,
  },
  {
    path: "/myWallet",
    element: <MyWalletPage />,
  },
  {
    path: "/awards-claim",
    element: <AwardsClaimPage />,
  },
  { path: "/profile", element: <ProfilePage /> },
  {
    path: "/categories",
    element: (
      <Protected route roles={[Roles.SUPERADMIN]} policy={RolePolicy.MATCH_ANY}>
        <CategoriesPage />
      </Protected>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <Protected route roles={[Roles.SUPERADMIN]} policy={RolePolicy.MATCH_ANY}>
        <DashboardPage />
      </Protected>
    )
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

const AppRoutes = () => {
  const routes = useRoutes(routesArray);
  return routes;
};

export const Router = () => (
  <BrowserRouter basename={`${process.env.REACT_APP_PUBLIC_URL}`}>
    <AppLayout>
      <Suspense fallback={<Spin spinning />}>
        <AppRoutes />
      </Suspense>
    </AppLayout>
  </BrowserRouter>
);
