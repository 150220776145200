import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Switch,
  Upload,
  Button,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { prizesAPI, currenciesAPI } from "@api";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import "./PrizeModal.styles.css";

const { TextArea } = Input;

export const PrizeModal = ({ prize, open, onCancel }) => {
  const [fileList, setFileList] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const selectedCurrency = Form.useWatch("currencyId", form);
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const queryClient = useQueryClient();

  const { data: currencies } = useQuery({
    queryKey: ["currencyOptions"],
    queryFn: () =>
      currenciesAPI.getCurrencies().then(({ data }) =>
        data.map((currency) => ({
          label: currency.symbol,
          value: currency.id,
        }))
      ),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate } = useMutation({
    mutationFn: ({ id, values }) => {
      setConfirmLoading(true);
      if (prize) {
        return prizesAPI.updatePrize(id, values);
      } else {
        return prizesAPI.createPrize(values);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["prizes"]);
      form.resetFields();
      setFileList([]);
      onCancel();
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
    onSettled: () => setConfirmLoading(false),
  });

  const selectedStockType = Form.useWatch("stockType", form);

  useEffect(() => {
    if (prize) {
      form.setFieldsValue({
        ...prize,
        currencyId: prize.currency?.id,
      });
      setFileList([
        {
          uid: prize?.imageUrl,
          thumbUrl: prize?.imageUrl,
          url: prize?.imageUrl,
          name: prize?.name
        },
      ])
    } else {
      setFileList([]);
    }
  }, [prize]);

  const language = i18n.language.substring(0, 2);

  return (
    <Modal
      title={prize ? t("prizeTable.edit") : t("prizeTable.add")}
      centered
      open={open}
      onOk={() => form.submit()}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      okText={t("userTable.form.btnSubmit")}
      confirmLoading={confirmLoading}
      maskClosable={true}
    >
      <Form
        form={form}
        name="prizeForm"
        onFinish={(values) => mutate({ id: prize?.id, values })}
        autoComplete="off"
        scrollToFirstError
        labelCol={{ span: language === "en" ? 5 : 8 }}
        wrapperCol={{ span: language === "en" ? 19 : 16 }}
      >
        <Form.Item
          label={t("prizeTable.name")}
          name="name"
          rules={[{ required: true, message: t("prizeTable.form.name") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("prizeTable.description")}
          name="description"
          rules={[
            { required: true, message: t("prizeTable.form.description") },
          ]}
        >
          <TextArea rows={4} style={{ resize: "none" }} />
        </Form.Item>
        <Form.Item
          label={t("prizeTable.price")}
          name="price"
          rules={[{ required: true, message: t("prizeTable.form.price") }]}
        >
          <InputNumber step={5} min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label={t("prizeTable.currency")}
          name="currencyId"
          rules={[
            {
              required: true,
              message: t("prizeTable.form.currency"),
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            allowClear
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
            options={currencies}
          />
        </Form.Item>
        <Form.Item
          label={t("prizeTable.cost")}
          name="cost"
          rules={[{ required: true, message: t("prizeTable.form.cost") }]}
        >
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            addonBefore={
              currencies?.find(
                (currency) => currency.value === selectedCurrency
              )?.label
            }
          />
        </Form.Item>
        <Form.Item
          label={t("prizeTable.available")}
          name="available"
          rules={[{ required: true, message: t("prizeTable.form.available") }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t("prizeTable.stockType")}
          name="stockType"
          rules={[{ required: true, message: t("prizeTable.form.stockType") }]}
        >
          <Select>
            <Select.Option value="STOCK">STOCK</Select.Option>
            <Select.Option value="DEMAND">DEMAND</Select.Option>
          </Select>
        </Form.Item>
        {selectedStockType === "STOCK" && (
          <Form.Item
            label={t("prizeTable.stockAmount")}
            name="stockAmount"
            rules={[
              { required: true, message: t("prizeTable.form.stockAmount") },
            ]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        )}
        <Form.Item
          label={t("prizeTable.imageUrl")}
          name="attachment"
          rules={[
            {
              required: !prize,
              message: t("prizeTable.form.imageUrl"),
            },
          ]}
          getValueFromEvent={({ file }) => file}
        >
          <Upload
            listType="picture"
            maxCount={1}
            accept="image/png, image/jpeg, image/jpg"
            beforeUpload={() => false}
            multiple={false}
            fileList={fileList}
            onChange={({ fileList }) => setFileList(fileList)}
          >
            <Button icon={<UploadOutlined />}>
              {t("userTable.form.btnUpload")}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label={t("prizeTable.observation")}
          name="observation"
        >
          <TextArea rows={4} style={{ resize: "none" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
