import React, { useState, useRef } from "react";
import {
  Col,
  Row,
  Divider,
  Card,
  Grid,
  Affix,
  Button,
  Space,
  Typography,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  BalanceCard,
  CreatePostCard,
  GiveableBalanceCard,
  Feed,
  MyRewards
} from "@components";
import "./Home.styles.css";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Leaderboard, LeaderboardType } from "components/Leaderboard/Leaderboard";
import { HandStarsIcon } from "@assets"
import Icon from "@ant-design/icons/lib/components/Icon";
import { TrophyOutlined } from "@ant-design/icons";

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const HomePage = () => {
  const [from, setFrom] = useState("");
  const [showAffix, setShowAffix] = useState(false);
  const { t } = useTranslation("common");
  const inputPostRef = useRef(null);
  const { lg } = useBreakpoint();

  const filters = [
    { value: "", label: t("leaderboard.filter.ALL_TIME") },
    {
      value: dayjs().subtract(30, "days").format("YYYY-MM-DD"),
      label: t("leaderboard.filter.LAST_30_DAYS"),
    },
  ];

  return (
    <Row
      justify="center"
      direction={{ xs: "column", md: "row" }}
      gutter={10}
      style={{ rowGap: "20px" }}
    >
      {lg ? (
        <Col
          span={24}
          lg={6}
          xl={5}
          xxl={5}
        >
          <Card
            style={{
              position: "sticky",
              top: "82px",
              backgroundColor: "transparent",
              boxShadow: "none",
              height: "calc(100vh - 100px)",
              overflow: "scroll",
              overflowX: "hidden",
              paddingRight: "15px",
            }}
            bodyStyle={{
              padding: "0",
            }}
            bordered={false}
            className="custom-scrollbar"
          >
            <Card style={{boxShadow: "0px 8px 12px 0px rgba(0, 0, 0, 0.05)"}}>
              <Space direction="vertical" size={24} style={{ width: "100%" }}>
                <Title level={4} style={{color: "#000", margin: 0, textAlign: "center"}}>
                  <Icon component={HandStarsIcon} style={{marginRight: 10}}/>
                  {t("marketplace.pointsBalance")}
                </Title>
                <GiveableBalanceCard inputPostRef={inputPostRef} />
                <BalanceCard />
              </Space>
            </Card>
            <Divider />
            <Card
              style={{
                boxShadow: "0px 8px 12px 0px rgba(0, 0, 0, 0.05)",
                overflow: "hidden",
                width: "100%",
              }}
            >
              <Title
                align="center"
                level={4}
                style={{
                  margin: 0,
                  marginBottom: 16,
                  position: "sticky",
                  top: 0,
                  background: "#fff",
                  padding: "0px",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                <Space>
                  <Link
                    to="/leaderboard"
                    component={Typography.Link}
                    style={{ color: "black" }}
                  >
                    <TrophyOutlined style={{marginRight: 8}} />
                    {t("leaderboard.title")}
                  </Link>
                </Space>
              </Title>
              <Title level={5} style={{ marginBottom: 15, marginLeft: 0, textAlign: "center" }}>{t(`leaderboard.type.topReceivers`)}</Title>
              <Space style={{ marginBottom: 15 }}>
                <Text>{t("leaderboard.filter.label")}</Text>
                <Select
                  options={filters}
                  defaultValue={""}
                  onChange={setFrom}
                  style={{
                    width: 120,
                  }}
                />
              </Space>
              <Leaderboard type={LeaderboardType.TOP_RECEIVERS} period={from} />
            </Card>
          </Card>
        </Col>
      ) : null}
      <Col
        span={24}
        lg={11}
        xl={12}
        xxl={12}
        style={{ maxWidth: 1000 }}
      >
        <CreatePostCard inputPostRef={inputPostRef} />
        <Affix
          offsetTop={64}
          style={{ height: showAffix ? "auto" : "0", overflow: "hidden" }}
          onChange={() => setShowAffix((prev) => !prev)}
        >
          <Space
            align="center"
            style={{
              width: "100%",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              type="default"
              style={{ top: showAffix ? 0 : -80 }}
              onClick={goToTop}
            >
              {t("scrollToTop")}
            </Button>
          </Space>
        </Affix>
        <Feed />
      </Col>
      {lg ? (
        <Col
          span={24}
          lg={7}
          xl={7}
          xxl={7}
        >
          <Card
            style={{
              position: "sticky",
              top: "82px",
              backgroundColor: "transparent",
              boxShadow: "none",
              height: "calc(100vh - 100px)",
              overflow: "scroll",
              overflowX: "hidden",
              paddingRight: "15px",
            }}
            bodyStyle={{
              padding: "0",
            }}
            bordered={false}
            className="custom-scrollbar"
          >
            <MyRewards />
          </Card>
        </Col>
      ) : null}
    </Row>
  );
};

export default HomePage;
