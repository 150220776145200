import React, { forwardRef, useState } from "react";
import {
  Grid,
  Avatar,
  Button,
  Card,
  Col,
  InputNumber,
  Row,
  Select,
  Space,
  Form,
  Spin,
  notification,
  Tooltip,
} from "antd";
import {
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import BraftEditor from "braft-editor";
import { postsAPI, usersAPI, tagsAPI, categoriesAPI } from "@api";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useDebounce } from "@hooks";
import 'braft-editor/dist/index.css'
import "./CreatePostCard.styles.css";

const { useBreakpoint } = Grid;

const controls = ['bold', 'italic', 'underline', 'strike-through', 'text-color', 'separator', 'emoji', 'separator', 'undo', 'redo', 'separator', 'clear'];

const CustomEditor = forwardRef( function CustomEditor(props, ref) {
  const { t } = useTranslation("common");

  return <BraftEditor
    {...props}
    controls={controls}
    style={{ border: "1px solid " + (props["aria-invalid"] ? "red" : "#AEAEAE"), borderRadius: 6 }}
    contentStyle={{ minHeight: "200px", height: "auto" }}
    language="en"
    placeholder={t("feed.form.post.placeholder")}
    ref={ref}
    stripPastedStyles={true}
  />
});

export const CreatePostCard = ({ inputPostRef }) => {
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const { md, lg } = useBreakpoint();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [userSearch, setUserSearch] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [validateTrigger, setValidateTrigger] = useState("onSubmit");
  const [isMultipleUsers, setIsMultipleUsers] = useState(false);
  const postMessage = Form.useWatch("post", form);
  const debouncedUserSearch = useDebounce(userSearch, 300);
  const [tagSearch, setTagSearch] = useState("");
  const debouncedTagSearch = useDebounce(tagSearch, 300);

  const { data: currentUser } = useQuery({
    queryKey: ["currentUser"],
    queryFn: () => usersAPI.getMyInfo().then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { data: categories } = useQuery({
    queryKey: ["categoryOptions"],
    queryFn: () =>
      categoriesAPI.getCategories().then(({ data: categories }) => 
        categories.data.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      ),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { data: tags, isLoading } = useQuery({
    queryKey: ["tagOptions", debouncedTagSearch],
    queryFn: () =>
      tagsAPI
        .getTags({
          search: debouncedTagSearch,
          pageSize: 10,
        })
        .then(({ data: { items } }) =>
          items.map((tag) => ({
            label: tag.name,
            value: tag.id,
          }))
        ),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate: createPostMutation } = useMutation({
    mutationFn: (data) => {
      setDisabledButton(true);
      data.post = data.post.toHTML();
      if (typeof data.recipientIds === 'number') data.recipientIds = [data.recipientIds]
      return postsAPI.createPost(data);
    },
    onSuccess: ({ data }) => {
      let totalAmount = 0;
      data.transaction.map((trans) => {
        totalAmount += trans.amount;
      })

      queryClient.invalidateQueries(["posts"]);
      queryClient.setQueryData(["wallet"], (prev) => ({
        ...prev,
        allowanceBalance: prev.allowanceBalance - totalAmount
      }));
      form.resetFields();
      setDisabledButton(false);
      setValidateTrigger("onSubmmit");
    },
    onError: ({ response }) => {
      setDisabledButton(false);
      const { error, errorData } = response.data;
      notification.error({ message: tError(error, { errorData }) });
    }
  });

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["usersOptions", debouncedUserSearch],
    queryFn: () =>
      usersAPI
        .searchUsers({
          search: debouncedUserSearch,
          limit: 10,
        })
        .then(({ data }) =>
          data.map((user) => ({
            label: user.fullName,
            value: user.id,
          }))
        ),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { data: wallet } = useQuery({
    queryKey: ["wallet"],
    queryFn: () => usersAPI.getMyWallet().then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  return (
    <Card className="CreatePostCard" style={{ marginBottom: 20 }}>
      <Row span={24} justify={"center"} gutter={[24, 24]} wrap={!lg}>
        <Col>
          <Avatar
            src={currentUser?.profilePictureUrl}
            alt="Current user profile picture"
            size={{
              xs: 50,
              sm: 64,
              md: 64,
              lg: 50,
              xl: 70,
              xxl: 70,
            }}
            icon={wallet?.user?.profilePictureUrl ? null : <UserOutlined />}
          />
        </Col>
        <Col flex="auto">
          <Form
            form={form}
            name="postForm"
            onFinish={createPostMutation}
            validateTrigger={validateTrigger}
            autoComplete="off"
            scrollToFirstError
            requiredMark={false}
          >
            <Space direction="vertical" size={16} style={{ width: "100%" }}>
              <Row gutter={[10, 10]} wrap={true}>
                <Col xs={22} sm={13}>
                  <Form.Item
                    name="recipientIds"
                    rules={
                      [isMultipleUsers ? {
                        type: "array",
                        required: true,
                        message: t("feed.form.recipient.errorMinUsers"),
                        min: 2
                      } : {
                        required: true,
                        message: t("feed.form.recipient.required"),
                      }]
                    }
                    style={{ margin: 0 }}
                  >
                    <Select
                      mode={isMultipleUsers && "multiple"}
                      showSearch
                      style={{
                        width: "100%",
                        border: "0.5px solid #aeaeae",
                        borderRadius: 6,
                      }}
                      placeholder={t("feed.form.recipient.placeholder")}
                      optionFilterProp="children"
                      onSearch={setUserSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={users}
                      suffixIcon={null}
                      allowClear
                      notFoundContent={
                        isFetchingUsers ? <Spin size="small" /> : null
                      }
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={2}
                  sm={1}
                  lg={2}
                  xl={1}
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title={t("feed.form.recipient.tooltip")}>
                    <UsergroupAddOutlined
                      className={
                        isMultipleUsers
                          ? "usergroup-icon active"
                          : "usergroup-icon"
                      }
                      onClick={() => {
                        setIsMultipleUsers(!isMultipleUsers);
                        form.resetFields(["recipientIds"]);
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col xs={24} sm={10} lg={9} xl={10}>
                  <Form.Item
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: t("feed.form.amount.required"),
                      },
                      {
                        type: "integer",
                        message: t("feed.form.amount.integer"),
                      },
                      {
                        type: "number",
                        min: 0,
                        max:
                          wallet?.allowanceBalance > 20 ||
                            wallet?.hasInfiniteBudget
                            ? 20
                            : wallet?.allowanceBalance,
                        message: t("feed.form.amount.minmax"),
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <InputNumber
                      addonAfter={t("marketplace.points")}
                      min={1}
                      max={
                        wallet?.allowanceBalance > 20 ||
                          wallet?.hasInfiniteBudget
                          ? 20
                          : wallet?.allowanceBalance
                      }
                      step={5}
                      controls={false}
                      style={{
                        width: "100%",
                        border: "0.5px solid #aeaeae",
                        borderRadius: 6,
                      }}
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="categoryId"
                    rules={[
                      {
                        required: true,
                        message: t("feed.form.category.required"),
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                        border: "0.5px solid #aeaeae",
                        borderRadius: 6,
                      }}
                      optionFilterProp="children"
                      placeholder={t("feed.form.category.placeholder")}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={categories}
                      suffixIcon={null}
                      allowClear
                      bordered={false}
                      onInputKeyDown={(e) => e.key === "Tab" && inputPostRef.current.requestFocus()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="post"
                    rules={[
                      {
                        message: t("feed.form.post.required"),
                        validator: (_, value) => {
                          if (!value.toText()?.trim()) return Promise.reject()
                          return Promise.resolve();
                        },
                        validateTrigger: 'onChange'
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <CustomEditor ref={inputPostRef} />
                  </Form.Item>
                </Col>
              </Row>
              <Row wrap={true} gutter={[16, 16]}>
                <Col flex={md ? "auto" : "100%"}>
                  <Form.Item
                    name="tags"
                    rules={[
                      {
                        required: false,
                        message: t("feed.form.tags.required"),
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      allowClear
                      placeholder={t("feed.form.tags.placeholder")}
                      bordered={false}
                      options={tags}
                      suffixIcon={null}
                      showSearch
                      onSearch={setTagSearch}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      optionFilterProp="label"
                      notFoundContent={isLoading ? <Spin size="small" /> : null}
                      onClear={() => setTagSearch("")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  flex={md ? "110px" : "100%"}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => setValidateTrigger("onChange")}
                    disabled={!postMessage?.toText() || disabledButton}
                    loading={createPostMutation.isLoading}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {t("feed.form.button")}
                  </Button>
                </Col>
              </Row>
            </Space>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
