import React from "react";
import { Modal, notification } from "antd";
import { PostCard } from "@components";
import { useQuery } from "@tanstack/react-query";
import { postsAPI } from "@api";
import { useTranslation } from "react-i18next";

export const PostModal = ({ post, postId, open, onCancel }) => {
  const { t: tError } = useTranslation("error");

  const { data: modalPost } = useQuery({
    queryKey: ["posts", postId],
    queryFn: () => postsAPI.getPost(postId).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
    enabled: !!postId
  });

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      maskClosable={true}
      width={700}
    >
      <PostCard
        post={modalPost || post}
        expanded
      />
    </Modal>
  );
};
