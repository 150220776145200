import React, { useEffect, useState } from "react";
import {
  Avatar,
  Col,
  Row,
  Typography,
  Space,
  Form,
  Input,
  Button,
  notification,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { postsAPI, usersAPI } from "@api";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";

const { Text } = Typography;
const { TextArea } = Input;

export const PostComments = ({ postId, comments, queryKey = ["posts", "all"] }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const [disabledButton, setDisabledButton] = useState(false);
  const queryClient = useQueryClient();
  const [localComments, setLocalComments] = useState([]);
  let newComments = [...comments];

  const { data: myInfo } = useQuery({
    queryKey: ["myInfo"],
    queryFn: () => usersAPI.getMyInfo().then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate } = useMutation({
    mutationFn: async (values) => {
      setDisabledButton(true);
      newComments = await postsAPI.createComment(postId, values);
      setLocalComments(newComments.data);
      // TODO: Refactor this. Local states with react query is useless.
    },
    onSuccess: () => {
      queryClient.setQueryData(queryKey, (oldData) => {
        return {
          ...oldData,
          pages: oldData.pages.map((page) => ({
            ...page,
            data: page.data.map((post) =>
              post.id === postId
                ? {
                  ...post,
                  comments: newComments,
                }
                : post
            ),
          })),
        };
      });
      queryClient.invalidateQueries(queryKey);
      form.resetFields();
      setDisabledButton(false);
    },
    onError: ({ response }) => {
      setDisabledButton(true);
      return notification.error({
        message: tError(response.data.message),
      })
    }
  });

  useEffect(() => {
    setLocalComments(comments);
  }, [comments]);

  return (
    <Row>
      <Col
        span={24}
        style={{
          border: "1px solid #f0f0f0",
          borderTop: "none",
          padding: 24,
        }}
      >
        <Row gutter={24}>
          <Col>
            <Avatar
              src={myInfo?.profilePictureUrl}
              alt={`${myInfo?.firstName} ${myInfo?.lastName} profile picture`}
              size="large"
              icon={<UserOutlined />}
              style={{ backgroundColor: "#BFBFBF" }}
            />
          </Col>
          <Col flex="auto">
            <Form
              form={form}
              name="commentForm"
              onFinish={mutate}
              autoComplete="off"
              requiredMark={false}
              style={{ width: "100%" }}
            >
              <Space direction="vertical" size={16} style={{ width: "100%" }}>
                <Form.Item
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: t("feed.post.comment.input"),
                    },
                  ]}
                  style={{ margin: 0 }}
                >
                  <TextArea
                    placeholder={t("feed.post.comment.placeholder")}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    style={{ resize: "none" }}
                    onPressEnter={() => form.submit()}
                  />
                </Form.Item>
                <Row span={24} justify={"end"}>
                  <Button type="primary" htmlType="submit" disabled={disabledButton}>
                    {t("feed.post.comment.button")}
                  </Button>
                </Row>
              </Space>
            </Form>
          </Col>
        </Row>
      </Col>
      {
        localComments.length > 0 && (
          <Col
            span={24}
            style={{
              border: "1px solid #f0f0f0",
              borderTop: "none",
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              padding: 24,
              display: "flex",
              flexDirection: "column",
              rowGap: 24,
            }}
          >
            {localComments
              .sort((a, b) => dayjs(a.createdAt) - dayjs(b.createdAt))
              .map((comment) => (
                <Row key={comment.id} gutter={24}>
                  <Col>
                    <Avatar
                      src={comment.user?.profilePictureUrl}
                      alt={`${comment.user?.firstName} ${comment.user?.lastName} profile picture`}
                      size="large"
                      icon={<UserOutlined />}
                      style={{ backgroundColor: "#BFBFBF" }}
                    />
                  </Col>
                  <Col flex="auto">
                    <Row>
                      <Col span={24}>
                        <Space
                          direction="vertical"
                          size={0}
                          style={{
                            width: "100%",
                            padding: "5px 10px",
                            background: "#F2F2F2",
                            borderRadius: 4,
                          }}
                        >
                          <Text
                            style={{
                              display: "block",
                              fontSize: "15px",
                              fontWeight: "600",
                            }}
                          >
                            {`${comment.user?.firstName} ${comment.user?.lastName}`}{" "}
                            -{" "}
                            <span style={{ fontSize: 12, fontWeight: "lighter" }}>
                              {dayjs(comment.createdAt).fromNow()}
                            </span>
                          </Text>
                          <Text
                            style={{
                              display: "block",
                              fontSize: 14,
                              marginBottom: 4,
                            }}
                          >
                            {comment.comment}
                          </Text>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
          </Col>
        )
      }
    </Row>
  );
};
