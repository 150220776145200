import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  notification,
  Divider,
  Switch,
  Alert,
} from "antd";
import { useTranslation } from "react-i18next";
import { usersAPI, countriesAPI } from "@api";
import dayjs from "dayjs";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import "./UserModal.styles.css";
import { UploadOutlined } from "@ant-design/icons";

export const UserModal = ({ user, open, onCancel }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const queryClient = useQueryClient();
  const budgetSwitchValue = Form.useWatch(
    ["wallet", "hasInfiniteBudget"],
    form
  );
  const superadminValue = Form.useWatch("superadmin", form);

  const { data: countries } = useQuery({
    queryKey: ["countryOptions"],
    queryFn: () =>
      countriesAPI.getCountries().then(({ data }) =>
        data.map((country) => ({
          label: country.name[language],
          value: country.id,
        }))
      ),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate } = useMutation({
    mutationFn: ({ id, values }) => {
      setConfirmLoading(true);
      if (user) {
        return usersAPI.updateUser(id, values);
      } else {
        return usersAPI.createUser(values);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
      form.resetFields();
      onCancel();
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
    onSettled: () => setConfirmLoading(false),
  });

  useEffect(() => {
    if (user)
      form.setFieldsValue({
        ...user,
        birthDate: user.birthDate ? dayjs(user.birthDate) : "",
        countryId: user.country?.id,
      });
  }, [user]);

  const language = i18n.language.substring(0, 2);

  return (
    <Modal
      title={user ? t("userTable.edit") : t("userTable.add")}
      centered
      open={open}
      onOk={() => form.submit()}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      okText={t("userTable.form.btnSubmit")}
      confirmLoading={confirmLoading}
      maskClosable={true}
      className="userModal"
    >
      <Form
        form={form}
        name="userForm"
        onFinish={(values) => 
          mutate({
            id: user?.id,
            values: {
              ...values,
              birthDate: values.birthDate ? values.birthDate?.format("YYYY-MM-DD") : null,
            },
          })
        }
        autoComplete="off"
        scrollToFirstError
        labelCol={{ span: language === "en" ? 7 : 8 }}
        wrapperCol={{ span: language === "en" ? 19 : 16 }}
      >
        <Form.Item
          label={t("userTable.firstName")}
          name="firstName"
          rules={[{ required: true, message: t("userTable.form.firstName") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("userTable.lastName")}
          name="lastName"
          rules={[{ required: true, message: t("userTable.form.lastName") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("userTable.imageUrl")}
          name="attachment"
          getValueFromEvent={({ file }) => file}
        >
          <Upload
            listType="picture"
            maxCount={1}
            accept="image/png, image/jpeg, image/jpg"
            beforeUpload={() => false}
            multiple={false}
            file={file}
            onChange={({ file }) => setFile(file)}
            disabled={user?.externalId}
          >
            <Button icon={<UploadOutlined />} disabled={user?.externalId}>
              {t("userTable.form.btnUpload")}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label={t("userTable.username")}
          name="username"
          rules={[{ required: true, message: t("userTable.form.username") }]}
        >
          <Input disabled={user} />
        </Form.Item>
        <Form.Item
          label={t("userTable.email")}
          name="email"
          rules={[
            {
              type: "email",
              message: t("userTable.form.email.valid"),
            },
            {
              required: true,
              message: t("userTable.form.email.required"),
            },
          ]}
        >
          <Input disabled={user} />
        </Form.Item>
        <Form.Item label={t("userTable.birthDate")} name="birthDate">
          <DatePicker
            format={["L", "DDMMYYYY", "DD/MM/YYYY", "MMDDYYYY", "MM/DD/YYYY"]}
            placeholder=""
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label={t("userTable.country")}
          name="countryId"
          rules={[
            {
              required: true,
              message: t("userTable.form.country"),
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            allowClear
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
            options={countries}
          />
        </Form.Item>
        <Divider />
        <Form.Item
          label={t("userTable.infiniteBudget.label")}
          name={["wallet", "hasInfiniteBudget"]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        {budgetSwitchValue ? (
          <Alert
            message={t("userTable.infiniteBudget.warning")}
            type="warning"
            showIcon
            style={{ marginBottom: "26px" }}
          />
        ) : null}
        <Form.Item
          label={t("userTable.superadmin.label")}
          name="superadmin"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        {superadminValue ? (
          <Alert
            message={t("userTable.superadmin.warning")}
            type="warning"
            showIcon
            style={{ marginBottom: "26px" }}
          />
        ) : null}
      </Form>
    </Modal>
  );
};
