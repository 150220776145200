import React from "react";
import {
  Space,
  Avatar,
  Statistic,
  Button,
  Spin,
  notification,
  Progress,
  Typography,
} from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { usersAPI } from "@api";

const { Text } = Typography;

export const GiveableBalanceCard = ({ inputPostRef }) => {
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const navigate = useNavigate();

  const { isLoading: walletLoading, data: wallet } = useQuery({
    queryKey: ["wallet"],
    queryFn: () => usersAPI.getMyWallet().then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  return (
    <Spin
      spinning={walletLoading}
      style={{ width: "100%", textAlign: "center", padding: "60px 0" }}
    >
      <Space align="center" size={8} style={{"flexWrap": "wrap", "justifyContent": "center"}}>
        <Avatar
          size="large"
          icon={<SmileOutlined />}
          style={{color: "#00AF31", background: "#CCEFD6"}}
        />
        <Statistic
          title=<Text style={{ color: "#000", fontSize: 16, fontWeight: 600 }}>{t("marketplace.giveableBalance")}</Text>
          valueStyle={{ color: "#00AF31", fontSize: "18px", fontWeight: 600 }}
          value={
            wallet?.hasInfiniteBudget
              ? t("unlimited")
              : wallet?.allowanceBalance
          }
          suffix={wallet?.hasInfiniteBudget ? "" : t("points")}
        />
      </Space>
      <Progress percent={wallet?.hasInfiniteBudget ? 100 : wallet?.allowanceBalance} showInfo={false} strokeColor='#00AF31' trailColor='#7FD798'/>
      <Button
        onClick={() => {
          if (!inputPostRef) return navigate("/");
          inputPostRef.current.requestFocus();
        }}
        type="primary"
        style={{ marginBottom: "0px", marginTop: "0px", height: 40, width: "100%", padding: "0px" }}
      >
        {t("marketplace.givePoints")}
      </Button>
    </Spin>
  );
};
