import React from "react";
import { Menu, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  WalletOutlined,
  ShoppingOutlined,
  TrophyOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "./MobileMenu.styles.css";

const { Text } = Typography;

export const MobileMenu = () => {
  const { t } = useTranslation("common");

  const MobileMenuItems = [
    {
      key: "myWallet",
      label: (
        <Link className="nav-link" to={"/myWallet"}>
          <WalletOutlined />
          <Text>{t("navbar.myWallet")}</Text>
        </Link>
      ),
    },
    {
      key: "marketplace",
      label: (
        <Link className="nav-link" to={"/marketplace"}>
          <ShoppingOutlined />
          <Text>{t("navbar.marketplace")}</Text>
        </Link>
      ),
    },
    {
      key: "feed",
      label: (
        <Link className="nav-link" to={"/"}>
          <HomeOutlined style={{ fontSize: 24 }} />
          <Text style={{ fontSize: 11, marginBottom: 3 }}>{t("navbar.feed")}</Text>
        </Link>
      ),
      className: "feed",
    },
    {
      key: "leaderboard",
      label: (
        <Link className="nav-link" to={"/leaderboard"}>
          <TrophyOutlined />
          <Text>{t("navbar.leaderboard")}</Text>
        </Link>
      ),
    },
    {
      key: "awards-claim",
      label: (
        <Link className="nav-link" to={"/awards-claim"}>
          <CrownOutlined />
          <Text>{t("navbar.awards-claim")}</Text>
        </Link>
      ),
    },
  ];

  return (
    <Menu
      mode="horizontal"
      items={MobileMenuItems}
      selectedKeys={[window.location.pathname]}
      className="mobile-menu"
      style={{
        display: "flex",
        position: "fixed",
        bottom: 0,
        width: "100vw",
        justifyContent: "space-evenly",
        borderTop: "1px solid #f0f0f0",
      }}
      disabledOverflow
    />
  );
};
