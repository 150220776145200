import { Link } from "react-router-dom";
import { Roles } from "../router/Protected";

export const BaseNavbar = [
  {
    key: "feed",
    label: (
      <Link className="nav-link" to={"/"}>
        feed
      </Link>
    ),
  },
  {
    key: "marketplace",
    label: (
      <Link className="nav-link" to={"/marketplace"}>
        marketplace
      </Link>
    ),
  },
  {
    key: "leaderboard",
    label: (
      <Link className="nav-link" to={"/leaderboard"}>
        leaderboard
      </Link>
    ),
  },
  {
    key: "awards-claim",
    label: (
      <Link className="nav-link" to={"/awards-claim"}>
        awards-claim
      </Link>
    ),
  },
];

export const AdminNavbar = [
  {
    key: "dashboard",
    roles: [Roles.SUPERADMIN],
    label: (
      <Link className="nav-link" to={"/dashboard"}>
        dashboard
      </Link>
    ),
  },
  {
    key: "awards",
    roles: [Roles.SUPERADMIN],
    label: (
      <Link className="nav-link" to={"/awards"}>
        awards
      </Link>
    ),
  },
  {
    key: "categories",
    roles: [Roles.SUPERADMIN],
    label: (
      <Link className="nav-link" to={"/categories"}>
        categories
      </Link>
    ),
  },
  {
    key: "claims",
    roles: [Roles.SUPERADMIN],
    label: (
      <Link className="nav-link" to={"/claims"}>
        claims
      </Link>
    ),
  },
  {
    key: "prizes",
    roles: [Roles.SUPERADMIN],
    label: (
      <Link className="nav-link" to={"/prizes"}>
        prizes
      </Link>
    ),
  },
  {
    key: "redeems",
    roles: [Roles.SUPERADMIN],
    label: (
      <Link className="nav-link" to={"/redeems"}>
        redeems
      </Link>
    ),
  },
  {
    key: "settings",
    roles: [Roles.SUPERADMIN],
    label: (
      <Link className="nav-link" to={"/settings"}>
        settings
      </Link>
    ),
  },
  {
    key: "users",
    roles: [Roles.SUPERADMIN],
    label: (
      <Link className="nav-link" to={"/users"}>
        users
      </Link>
    ),
  },
];

export const UserNavbar = [
  {
    key: "myRewards",
    label: (
      <Link className="nav-link" to={"/myRewards"}>
        myRewards
      </Link>
    ),
  },
  {
    key: "myRedeems",
    label: (
      <Link className="nav-link" to={"/myRedeems"}>
        myRedeems
      </Link>
    ),
  },
  {
    key: "myClaims",
    label: (
      <Link className="nav-link" to={"/myClaims"}>
        myClaims
      </Link>
    ),
  },
];
