export const OrderByDirection = {
  ascend: 'ASC',
  descend: 'DESC',
}

export const DateFormatMapping = {
  en: 'MM/DD/YYYY',
  es: 'DD/MM/YYYY',
};


export const BackgroundColorsLabels = ["#2966c7", "#fd0287", "#e6511e", "#6600aa"]
