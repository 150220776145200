import React, { useState } from "react";
import { LeaderboardPeriod, Leaderboard, LeaderboardType } from "components/Leaderboard/Leaderboard";
import { Card, Col, Grid, Row, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const { useBreakpoint } = Grid;
const { Text, Title } = Typography;
const leaderboardTypeEntries = Object.entries(LeaderboardType);
const leaderboardPeriodEntries = Object.entries(LeaderboardPeriod);

const LeaderboardScreen = () => {
  const [from, setFrom] = useState({
    [LeaderboardType.TOP_RECEIVERS]: "",
    [LeaderboardType.TOP_GIVERS]: "",
    [LeaderboardType.TOP_GIVERS_ADMIN]: ""
  });
  const { t } = useTranslation("common");
  const { lg } = useBreakpoint();

  const filters = [
    { value: "", label: t("leaderboard.filter.ALL_TIME") },
    {
      value: dayjs().subtract(30, "days").format("YYYY-MM-DD"),
      label: t("leaderboard.filter.LAST_30_DAYS"),
    },
  ];

  return (
    <>
      <Title style={{ marginLeft: 60 }}>{t("leaderboard.title")}</Title>

      {
        leaderboardTypeEntries.map(([typeKey, typeValue], index) => (
          lg ? (
            <Row key={typeKey} justify={"center"} gutter={[80, 20]}>
              <Col span={24}>
                <Title level={3} style={{ marginBottom: 0, marginLeft: 0, textAlign: "center" }}>
                  {t(`leaderboard.type.${typeValue}`)}
                </Title>
              </Col>
              {
                leaderboardPeriodEntries.map(([periodKey, periodValue]) => (
                  <Col key={periodKey} span={12} xl={10} xxl={9}>
                    <Card>
                      <Title level={4} style={{ margin: 0, marginBottom: 10 }}>
                        {t(`leaderboard.filter.${periodKey}`)}
                      </Title>
                      <Leaderboard type={typeValue} period={periodValue} podium />
                    </Card>
                  </Col>
                ))
              }
            </Row>
          ) : (
            <Row key={index} justify={"center"}>
              <Col span={24}>
                <Title level={3} style={{ marginLeft: 0, textAlign: "center" }}>
                  {t(`leaderboard.type.${typeValue}`)}
                </Title>
              </Col>
              <Col span={20} md={{ span: 16 }}>
                <Card
                  style={{ padding: 30, width: "100%" }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Space style={{ marginBottom: 15 }}>
                    <Text>{t("leaderboard.filter.label")}</Text>
                    <Select
                      options={filters}
                      defaultValue={""}
                      onChange={(value) => setFrom(prev => ({ ...prev, [typeKey]: value }))}
                      style={{
                        width: 120,
                      }}
                    />
                  </Space>
                  <Leaderboard type={typeValue} period={from[typeKey]} />
                </Card>
              </Col>
            </Row>
          )
        ))
      }

    </>
  );
};

export default LeaderboardScreen;
