import React from "react";
import { Row, Col, Divider } from "antd";
import { BalanceCard, GiveableBalanceCard } from "@components";
import "./MyWallet.styles.css";

const MyWalletPage = () => {
  return (
    <Row justify="center">
      <Col span={24}>
        <GiveableBalanceCard />
        <Divider />
        <BalanceCard />
      </Col>
    </Row>
  );
};

export default MyWalletPage;
