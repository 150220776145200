import React from "react";
import {
  Spin,
  Divider,
  Skeleton,
  List,
  notification,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import {
  PostCard, PostModal,
} from "@components";
import {
  useInfiniteQuery,
} from "@tanstack/react-query";
import { postsAPI } from "@api";
import { useSearchParams } from "react-router-dom";

export const FeedFilters = {
  USER: 'user',
  RECIPIENT: 'recipient'
}

export const Feed = ({ filterBy, queryKey = ["posts", "all"] }) => {
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  
  const [searchParams, setSearchParams] = useSearchParams();
  const postId = searchParams.get('postId');

  const {
    isLoading: postsLoading,
    data: posts,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      return await postsAPI
        .getPosts({ page: pageParam, filterBy })
        .then(({ data }) => data);
    },
    getNextPageParam: (lastPage) => lastPage.nextPage || undefined,
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  return (
    <Spin
      tip="Loading"
      spinning={postsLoading}
      style={{ width: "100%", textAlign: "center", padding: "60px 0" }}
    >
      {
        posts && posts.pages[0].data.length > 0 ? (
          <InfiniteScroll
            dataLength={posts.pages?.reduce(
              (acc, page) => acc + page.data.length,
              0
            )}
            next={() =>
              fetchNextPage({
                pageParam: posts.pages[posts.pages.length - 1].nextPage,
              })
            }
            hasMore={hasNextPage}
            loader={
              <Skeleton
                avatar
                paragraph={{
                  rows: 1,
                }}
                active
              />
            }
            endMessage={
              <Divider plain>{`${t("feed.endMessage")} 🤐`}</Divider>
            }
            scrollableTarget="scrollableDiv"
            pullDownToRefresh
            refreshFunction={() => refetch()}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                {" "}
                {t("feed.pullToRefresh.pull")}{" "}
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                {t("feed.pullToRefresh.release")}
              </h3>
            }
          >
            <List
              gutter={20}
              dataSource={posts?.pages?.flatMap((page) => page.data) ?? []}
              renderItem={(post) => <PostCard key={post.id} post={post} queryKey={queryKey} />}
            />
          </InfiniteScroll>
        ) : (
          <Divider plain>{`${t("feed.endMessage")} 🤐`}</Divider>
        )
      }

      <PostModal postId={postId} onCancel={() => setSearchParams("")} open={!!postId} />
    </Spin>
  )
}
