import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Typography,
  Card,
  Form,
  Button,
  Spin,
  Input,
  Select,
  DatePicker,
  Upload,
  Avatar,
  Space,
  Grid,
  notification,
} from "antd";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { countriesAPI, usersAPI } from "@api";
import dayjs from "dayjs";
import "./Profile.styles.css";

const { useBreakpoint } = Grid;

const ProfilePage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState([]);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const queryClient = useQueryClient();
  const { md } = useBreakpoint();

  const { data: countries } = useQuery({
    queryKey: ["countryOptions"],
    queryFn: () =>
      countriesAPI.getCountries().then(({ data }) =>
        data.map((country) => ({
          label: country.name[language],
          value: country.id,
        }))
      ),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { data: myInfo, isLoading: myInfoLoading } = useQuery({
    queryKey: ["myInfo"],
    queryFn: () => usersAPI.getMyInfo().then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate } = useMutation({
    mutationFn: ({ id, values }) => {
      setIsSubmitting(true);
      return usersAPI.updateUser(id, values);
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["myInfo"], data);
      form.resetFields();
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
    onSettled: () => setIsSubmitting(false),
  });

  useEffect(() => {
    if (myInfo)
      form.setFieldsValue({
        ...myInfo,
        birthDate: myInfo.birthDate ? dayjs(myInfo.birthDate) : "",
        countryId: myInfo.country?.id,
      });
  }, [myInfo]);

  const language = i18n.language.substring(0, 2);

  return (
    <Spin
      tip="Loading"
      spinning={myInfoLoading}
      style={{ width: "100%", textAlign: "center", padding: "60px 0" }}
    >
      <Row justify="center" align="middle" className="profile-page">
        <Col span={22}>
          <Typography.Title align="left" level={2} style={{ marginTop: 0 }}>
            {t("navbar.profile")}
          </Typography.Title>
        </Col>
        <Col span={22}>
          <Card>
            <Form
              form={form}
              name="profileForm"
              layout={md ? "horizontal" : "vertical"}
              onFinish={(values) =>
                mutate({
                  id: myInfo?.id,
                  values: {
                    ...values,
                    birthDate: values.birthDate?.format("YYYY-MM-DD"),
                  },
                })
              }
              autoComplete="off"
              scrollToFirstError
              labelCol={{ span: md ? 4 : 24 }}
              wrapperCol={{ span: md ? 20 : 24 }}
              requiredMark={false}
              labelWrap={true}
            >
              <Form.Item
                label={t("userTable.imageUrl")}
                name="attachment"
                getValueFromEvent={({ file }) => file}
                style={{ textAlign: md ? "left" : "center" }}
              >
                <Upload
                  listType="picture"
                  maxCount={1}
                  accept="image/png, image/jpeg, image/jpg"
                  beforeUpload={() => false}
                  multiple={false}
                  file={file}
                  onChange={({ file }) => setFile(file)}
                >
                  <Space direction={md ? "horizontal" : "vertical"}>
                    <Avatar
                      src={myInfo?.profilePictureUrl}
                      alt="Current user profile picture"
                      size={100}
                      icon={myInfo?.profilePictureUrl ? null : <UserOutlined />}
                      style={{ marginRight: "20px", cursor: "pointer" }}
                    />
                    <Button icon={<UploadOutlined />}>
                      {t("userTable.form.btnUpload")}
                    </Button>
                  </Space>
                </Upload>
              </Form.Item>
              <Form.Item
                label={t("userTable.firstName")}
                name="firstName"
                rules={[
                  { required: true, message: t("userTable.form.firstName") },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("userTable.lastName")}
                name="lastName"
                rules={[
                  { required: true, message: t("userTable.form.lastName") },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("userTable.username")}
                name="username"
                rules={[
                  { required: true, message: t("userTable.form.username") },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                label={t("userTable.email")}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: t("userTable.form.email.valid"),
                  },
                  {
                    required: true,
                    message: t("userTable.form.email.required"),
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item label={t("userTable.birthDate")} name="birthDate">
                <DatePicker
                  format={[
                    "L",
                    "DDMMYYYY",
                    "DD/MM/YYYY",
                    "MMDDYYYY",
                    "MM/DD/YYYY",
                  ]}
                  placeholder=""
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                label={t("userTable.country")}
                name="countryId"
                rules={[
                  {
                    required: true,
                    message: t("userTable.form.country"),
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={countries}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <Button type="primary" htmlType="submit" loading={isSubmitting}>
                  {t("profile.save")}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default ProfilePage;
