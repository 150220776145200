import React from "react";
import { Row, Col, Result } from "antd";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation(["error", "common"]);

  return (
    <Row
      justify="center"
      style={{ width: "100%", height: "100%", alignSelf: "center" }}
    >
      <Col span={24}>
        <Result
          status="404"
          title={t("error404", { ns: "error" })}
          subTitle={t("error404Detail", { ns: "error" })}
        />
      </Col>
    </Row>
  );
};

export default NotFoundPage;
