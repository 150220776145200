import React, { useEffect } from "react";
import { Row, Col, Layout, Button, Menu, Grid, Dropdown, Divider, notification } from "antd";
import { ToolOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import { BaseNavbar, AdminNavbar, UserNavbar } from "./NavbarLinks";
import logoExo from "../assets/logo_exo.png";
import "./layoutStyle.css";
import { Link } from "react-router-dom";
import {
  REACT_APP_KEYCLOAK_CLIENT_ID,
  useHasPermission,
} from "hooks/useHasPermission";
import { Roles } from "@router/Protected";
import { usersAPI } from "@api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const { Header } = Layout;
const { useBreakpoint } = Grid;

export const MainHeader = () => {
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const {
    keycloak: { token, logout },
  } = useKeycloak();
  const { resource_access } = decodeJwt(token);
  const queryClient = useQueryClient();
  const { lg } = useBreakpoint();
  const hasPermission = useHasPermission();

  const language = i18n.language.slice(0, 2);

  const { data: currentUser } = useQuery({
    queryKey: ["currentUser"],
    queryFn: () => usersAPI.getMyInfo().then(({ data }) => data),
    onSuccess: (user) => {
      const key = user?.preferredLanguage;
      if (!key || key === language) return;
      i18n.changeLanguage(key);
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate } = useMutation({
    mutationFn: ({ values }) => {
      return usersAPI.updateMe(values);
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["myInfo"], data);
    },
    onError: ({ response }) => {
      notification.error({
        message: tError(response.data.message),
      })
    }
  });

  const filterSecureNav = (items) => {
    const filteredItems = [];
    for (const item of items) {
      const newItem = { ...item };
      if (
        item.roles &&
        !item.roles?.some((role) =>
          resource_access?.[REACT_APP_KEYCLOAK_CLIENT_ID]?.roles?.includes(role)
        )
      )
        continue;
      if (!item.children) {
        if (typeof item.label === "string")
          newItem["label"] = t(`navbar.${item.label}`);
        else
          newItem["label"] = React.cloneElement(
            item.label,
            [],
            t(`navbar.${item.label.props.children}`)
          );
        filteredItems.push(newItem);
        continue;
      }
      const filteredChildren = filterSecureNav(item.children);
      filteredItems.push({ ...item, children: filteredChildren });
    }
    return filteredItems;
  };
  
  return (
    <Header
      style={{
        backgroundColor: "white",
        fontSize: 12,
        fontWeight: "bold",
        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
        position: "fixed",
        zIndex: 10,
        width: "100%",
      }}
    >
      {process.env.REACT_APP_ENVIRONMENT === "staging" && (
        <div className="testing-badge">STAGING</div>
      )}
      <Row style={{ width: "100%", justifyContent: "space-between" }}>
        <Col flex="100px">
          <Link
            to="/"
            style={{ display: "flex", alignItems: "center", height: "67px" }}
          >
            <img
              src={logoExo}
              style={{ height: "25px" }}
              alt="Exomindset logo"
            />
          </Link>
        </Col>
        {lg ? (
          <Col flex="auto">
            <Menu
              mode="horizontal"
              style={{ justifyContent: "center", minWidth: 0, flex: "auto" }}
              items={filterSecureNav(BaseNavbar)}
              selectedKeys={[window.location.pathname]}
            />
          </Col>
        ) : null}
        <Col
          align="end"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            columnGap: 16,
          }}
        >
          <Dropdown
            menu={{
              items: [
                { key: "es", label: t("languages.es") },
                { key: "en", label: t("languages.en") },
              ],
              selectable: true,
              defaultSelectedKeys: currentUser?.preferredLanguage,
              onSelect: ({ key }) => {
                i18n.changeLanguage(key);
                mutate({
                  values: {
                    preferredLanguage: key
                  }
                })
              },
            }}
            placement="bottomRight"
          >
            <Button
              style={{ padding: "0 8px", height: "auto", borderRadius: "4px" }}
            >
              {t(`languages.${language}`)}
            </Button>
          </Dropdown>
          {hasPermission([Roles.SUPERADMIN]) ? (
            <Dropdown
              menu={{ items: filterSecureNav(AdminNavbar) }}
              placement="bottomRight"
            >
              <ToolOutlined
                style={{
                  fontSize: "20px",
                  color: "#2966c6",
                  cursor: "pointer",
                }}
              />
            </Dropdown>
          ) : null}
          <Dropdown
            menu={{
              items: [
                ...filterSecureNav(UserNavbar),
                {
                  key: "logout",
                  label: (
                    <>
                      <Divider style={{ marginTop: 4, marginBottom: 10 }} />
                      <Button
                        className="nav-link"
                        type="ghost"
                        onClick={() => logout()}
                        style={{ padding: 0, height: "auto" }}
                      >
                        {t("navbar.logout")}
                      </Button>
                    </>
                  ),
                },
              ],
            }}
            placement="bottomRight"
            overlayStyle={{ paddingTop: 10 }}
          >
            <UserOutlined
              style={{ fontSize: "20px", color: "#2966c6", cursor: "pointer" }}
            />
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};
