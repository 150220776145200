import React from "react";
import {
  Row,
  Col,
  Typography,
  Image,
  Space,
  Avatar,
  Grid,
  Table,
  notification,
} from "antd";
import { CalendarOutlined, TagOutlined } from "@ant-design/icons";
import { usersAPI } from "@api";
import { useTranslation } from "react-i18next";
import { fallbackImage } from "@assets";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import "./MyRedeems.styles.css";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const MyRedeemsPage = () => {
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const { md } = useBreakpoint();

  const { isLoading, data: redeems } = useQuery({
    queryKey: ["myRedeems"],
    queryFn: () => usersAPI.getMyRedemptions().then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const columns = [
    {
      title: t("redeemTable.prize"),
      dataIndex: "prize",
      fixed: md ? "left" : "",
      width: 300,
      sorter: (a, b) => a.prize.name.localeCompare(b.prize.name),
      filteredValue: null,
      render: (prize) => prize.name,
      onFilter: (value, record) => {
        return (
          record.prize.name.toLowerCase().includes(value.toLowerCase()) ||
          record.user.firstName.toLowerCase().includes(value.toLowerCase()) ||
          record.user.lastName.toLowerCase().includes(value.toLowerCase()) ||
          record.status.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: t("redeemTable.price"),
      dataIndex: "prize",
      width: 200,
      render: (prize) => (
        <Space align="center" size={12}>
          <Avatar
            size="default"
            icon={<TagOutlined key="tag" style={{ color: "#2966c6" }} />}
            style={{ background: "#edeffb" }}
          />
          <Text
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#2966c6",
            }}
          >{`${prize.price} ${t("marketplace.points")}`}</Text>
        </Space>
      ),
    },
    {
      title: t("redeemTable.createdAt"),
      dataIndex: "createdAt",
      width: 210,
      sorter: (a, b) => dayjs(a.createdAt) - dayjs(b.createdAt),
      render: (date) => (
        <Text style={{ color: "#8C8C8C" }}>
          <CalendarOutlined style={{ fontSize: "16px", marginRight: "10px" }} />
          {dayjs(date).locale(language).format("lll")}
        </Text>
      ),
    },
    {
      title: t("redeemTable.status"),
      dataIndex: "status",
      width: 120,
      render: (status) => (
        <Title
          align={md ? "left" : "center"}
          level={5}
          style={{
            margin: 0,
          }}
        >
          {t(`status.${status}`)}
        </Title>
      ),
    },
    {
      title: t("prizeTable.imageUrl"),
      dataIndex: "prize",
      width: 110,
      render: (prize) => (
        <Image
          width={50}
          height={50}
          src={prize.imageUrl}
          alt={prize.name}
          fallback={fallbackImage}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          wrapperStyle={{
            width: "50px",
            height: "50px",
            overflow: "hidden",
            borderRadius: "5px",
          }}
        />
      ),
    },
    {
      title: t("redeemTable.closed"),
      dataIndex: "closed",
      width: 210,
      sorter: (a, b) => dayjs(a.closed) - dayjs(b.closed),
      render: (date) => (
        <Text style={{ color: "#8C8C8C" }}>
          <CalendarOutlined style={{ fontSize: "16px", marginRight: "10px" }} />
          {date ? dayjs(date).locale(language).format("lll") : "-"}
        </Text>
      ),
    },
  ];

  const language = i18n.language.substring(0, 2);

  return (
    <>
      <Row justify="center" align="middle" className="container-row">
        <Col span={22}>
          <Typography.Title align="left" level={2} style={{ marginTop: 0 }}>
            {t("navbar.myRedeems")}
          </Typography.Title>
        </Col>
        <Col
          span={22}
          align="center"
          style={{
            height: "100%",
            backgroundColor: "#fff",
          }}
        >
          <Table
            dataSource={redeems ?? []}
            columns={columns}
            pagination={false}
            rowKey={"id"}
            scroll={{ x: "max-content", y: "calc(100vh - 335px)" }}
            loading={isLoading}
            borderRadius={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default MyRedeemsPage;
