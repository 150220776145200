import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Typography,
  Tag,
  Space,
  Tooltip,
  ConfigProvider,
  Image,
  Grid,
} from "antd";
import { UserOutlined, CommentOutlined, TagOutlined, ArrowRightOutlined, StarOutlined } from "@ant-design/icons";
import { useTranslation, Trans } from "react-i18next";
import dayjs from "dayjs";
import { PostComments, PostModal, LikeButton } from "@components";
import { capitalize } from "helpers/capitalize";
import 'braft-editor/dist/output.css'
import "./PostCard.styles.css";
import { fallbackImage, Quotes } from "@assets";
import { Link } from "react-router-dom";

const { Text, Title } = Typography;
const TAG_COLORS = [
  "blue",
  "lime",
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "green",
  "cyan",
  "geekblue",
  "purple",
];
const presetOpacity = "4D";
const { useBreakpoint } = Grid;

export const TransactionTypes = {
  REWARD: 'REWARD',
  ALLOWANCE: 'ALLOWANCE',
  REDEMPTION: 'REDEMPTION',
  ALLOWANCE_BOOST: 'ALLOWANCE_BOOST',
  REFUND: 'REFUND',
  CLAIM: 'CLAIM',
}

export const StockTypes = {
  STOCK: 'STOCK',
  DEMAND: 'DEMAND'
}

export const PostCard = ({ post, expanded, queryKey = ["posts", "all"] }) => {
  if (!post) return
  
  const isMultiplePost = post.recipient?.length > 1;

  const recipientsFullNames = post.recipient?.length > 0 && capitalize(post.recipient.map(({ fullName }) => fullName).join(', '));
  const userFullName = post.user?.fullName && capitalize(post.user.fullName);
  const amount = post.transaction?.[0]?.amount;

  const [showPostModal, setShowPostModal] = useState(false);
  const { t } = useTranslation("common");
  const { xs, lg, xl } = useBreakpoint();

  return (
    <Row>
      <Col span={24} style={{ backgroundColor: "white", marginBottom: 24, boxShadow: "0px 3px 12px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
        {
          !post.prize ? (
            <>
              <Card
                style={{
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  marginBottom: expanded && 0,
                  backgroundColor: post.category?.color + presetOpacity
                }}
                className={post.transaction?.[0]?.type === TransactionTypes.REWARD ? "post-card"
                  : post.transaction?.[0]?.type === TransactionTypes.CLAIM && "post-card background-claim"
                }
              >
                <Row style={{ flexDirection: "column", gap: 12 }}>
                  <Col
                    flex={{
                      xs: 50,
                      sm: 50,
                      md: 50,
                      lg: 50,
                      xl: 80,
                      xxl: 80,
                    }}
                  >
                    <Row className="row-avatars">
                      {post.transaction?.[0]?.type === TransactionTypes.REWARD ? (
                        isMultiplePost ? (
                          <ConfigProvider
                            theme={{
                              components: {
                                Avatar: {
                                  groupOverlapping: 0
                                },
                              },
                            }}
                          >
                            <Avatar.Group
                              maxCount={2}
                              size={{
                                xs: 50,
                                sm: 64,
                                md: 64,
                                lg: 50,
                                xl: 56,
                                xxl: 56,
                              }}
                              maxStyle={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                              }}
                            >
                              {post.recipient.map(rec => (
                                <Tooltip key={rec.id} title={`${rec.firstName} ${rec.lastName}`} placement="top">
                                  <Avatar
                                    src={rec.profilePictureUrl}
                                    alt="Recipient profile picture"
                                    size={{
                                      xs: 50,
                                      sm: 64,
                                      md: 64,
                                      lg: 50,
                                      xl: 56,
                                      xxl: 56,
                                    }}
                                    icon={<UserOutlined />}
                                    style={{ backgroundColor: "#BFBFBF" }}
                                  />
                                </Tooltip>
                              ))}
                            </Avatar.Group>
                          </ConfigProvider>
                        ) : (
                          <Tooltip title={`${post.recipient?.[0]?.firstName} ${post.recipient?.[0]?.lastName}`} placement="top">
                            <Avatar
                              src={post.recipient?.[0]?.profilePictureUrl}
                              alt="Recipient profile picture"
                              size={{
                                xs: 50,
                                sm: 64,
                                md: 64,
                                lg: 50,
                                xl: 56,
                                xxl: 56,
                              }}
                              icon={<UserOutlined />}
                              style={{ backgroundColor: "#BFBFBF" }}
                            />
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip title={`${post.recipient?.[0]?.firstName} ${post.recipient?.[0]?.lastName}`} placement="top">
                          <Avatar
                            src={post.recipient?.[0]?.profilePictureUrl}
                            alt="User profile picture"
                            size={{
                              xs: 50,
                              sm: 64,
                              md: 64,
                              lg: 50,
                              xl: 56,
                              xxl: 56,
                            }}
                            icon={<UserOutlined />}
                            style={{ backgroundColor: "#BFBFBF" }}
                          />
                        </Tooltip>
                      )}
                      <Row style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}>
                        <Text
                          className="text-title"
                        >
                          <Trans
                            t={t}
                            components={[
                              <strong key={post.user?.id}></strong>,
                              <strong key={post.transaction[0]?.id}></strong>,
                              <strong key={post.recipient[0]?.id}></strong>,
                            ]}
                          >
                            {post.transaction?.[0]?.type === TransactionTypes.REWARD
                              ? t("feed.post.message_received_reward", {
                                received: post.recipient?.length,
                                recipients: recipientsFullNames,
                                amount,
                              })
                              : t("feed.post.received_award", {
                                recipients: recipientsFullNames,
                                amount,
                                count: post.recipient?.length
                              })}
                          </Trans>
                        </Text>
                        <Text
                          style={{
                            display: "block",
                          }}
                        >
                          {dayjs(post.createdAt).fromNow()}
                        </Text>
                      </Row>
                    </Row>
                  </Col>
                  {post.tags?.length ? (
                    <Col flex="auto">
                      <Space size={8} wrap={true}>
                        {post.tags?.map((tag, idx) => (
                          <Tag
                            key={tag.id}
                            color={TAG_COLORS[idx]}
                            style={{ marginInlineEnd: 0 }}
                          >
                            {`#${tag.name}`}
                          </Tag>
                        ))}
                      </Space>
                    </Col>
                  ) : null}
                  <Col style={{ background: "rgb(255 255 255 / 50%)", borderRadius: 16, padding: 16 }}>
                    <Text className="text-post">
                      <Col>
                        <Quotes color={post.category?.color} />
                      </Col>
                      <blockquote
                        style={{ marginBottom: 20, marginTop: 0, borderInlineStart: 0 }}
                        className="braft-output-content"
                        dangerouslySetInnerHTML={{ __html: post.post }}>
                      </blockquote>
                    </Text>
                    {
                      post.transaction[0]?.type === TransactionTypes.REWARD && (
                        <Row justify="end" align="middle">
                          <Tooltip title={`${post.user?.firstName} ${post.user?.lastName}`} placement="top">
                            <Avatar
                              src={post.user?.profilePictureUrl}
                              alt="User profile picture"
                              size={{
                                xs: 50,
                                sm: 64,
                                md: 64,
                                lg: 50,
                                xl: 40,
                                xxl: 40,
                              }}
                              icon={<UserOutlined />}
                              className="avatar-user"
                              style={{
                                backgroundColor: "#BFBFBF",
                                marginRight: 4
                              }}
                            />
                          </Tooltip>
                          <cite style={{ fontSize: 16, fontStyle: "italic", textAlign: "right" }}>
                            {userFullName}
                          </cite>
                        </Row>
                      )
                    }
                  </Col>
                  {
                    post.transaction[0]?.type === TransactionTypes.REWARD && (
                      <Col>
                        <Tag color="blue">
                          {`#${post.category?.name.toLowerCase()}`}
                        </Tag>
                      </Col>
                    )
                  }
                </Row>
              </Card>
              <Row className={expanded ? "bottom-card bottom-card-expanded" : "bottom-card"}>
                <Row>
                  <Col>
                    <LikeButton
                      key={`likes-${post.id}`}
                      postId={post.id}
                      likes={post.likes}
                      likedByUser={post.likedByUser}
                      queryKey={queryKey}
                    />
                  </Col>
                  {
                    !expanded && (
                      <Col>
                        <Button
                          key={`comments-${post.id}`}
                          type="link"
                          icon={<CommentOutlined style={{ fontSize: 20 }} />}
                          block
                          onClick={() => setShowPostModal(true)}
                        >
                          <Text
                            color="black"
                            style={{ fontSize: 14, fontWeight: "300" }}
                          >{`${post.comments?.length}`}</Text>
                        </Button>
                      </Col>
                    )
                  }
                </Row>
              </Row>
            </>
          ) : (
            <Card
              hoverable
              style={{
                display: "flex",
                flexDirection: "column",
                padding: xl && "0px 60px"
              }}
              className="post-card"
              bodyStyle={{
                display: "flex",
                flexDirection: (xs || (lg && !xl)) ? "column" : "row",
                alignItems: "center",
                rowGap: (xs || (lg && !xl)) && 8,
                paddingTop: (xs || (lg && !xl)) && 0,
                justifyContent: "space-between",
              }}
              
              cover={
                <>
                  <Space style={{ display: "flex", justifyContent: "space-between", flexDirection: xs && "column", alignItems: xs && "baseline" }}>
                    <Title level={4} style={{ marginLeft: 30 }}>
                      <StarOutlined style={{ marginRight: 10 }} />
                      {t("marketplace.featuredPrize")}
                    </Title>
                    <Space
                      style={{
                        marginTop: !xs && 26,
                        marginRight: !xs && 30,
                        marginLeft: xs && 30,
                        backgroundColor: "white",
                        borderRadius: 12,
                        padding: "2px 8px",
                        border: "2px solid #2966C6",
                      }}
                    >
                      <Avatar
                        size="default"
                        icon={
                          <TagOutlined
                            key="tag"
                            style={{ color: "#2966c6" }}
                          />
                        }
                        style={{ background: "white", height: "100%", width: "100%" }}
                      />
                      <Text
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: "#2966c6",
                        }}
                      >{`${post.prize.price} ${t("marketplace.points")}`}</Text>
                    </Space>
                  </Space>
                  <Image
                    src={post.prize.imageUrl}
                    alt={post.prize.name}
                    fallback={fallbackImage}
                    style={{
                      height: 250,
                      objectFit: "contain",
                      padding: 10,
                    }}
                    height={250}
                    preview={false}
                  />
                </>
              }
            >
              <Row style={{ marginRight: (xs || (lg && !xl)) ? 0 : 40 }}>
                <Title level={5} style={{ margin: 0, width: "100%" }}>
                  {post.prize.name}
                </Title>
                <Text type="secondary" style={{ marginTop: 6 }}>
                  {post.prize.description}
                </Text>
              </Row>
              {post.prize.stockAmount < 1 && post.prize.stockType === StockTypes.STOCK ? (
                t("marketplace.noStock")
              ) : (
                <Link to="/marketplace">
                  <Button style={{ borderColor: "#000", color: "#000", fontWeight: 600, height: 40, padding: "4px 20px" }}>
                    {t("marketplace.goToMarketplace")}
                    <ArrowRightOutlined />
                  </Button>
                </Link>
              )}
            </Card>
          )
        }

        {expanded ? (
          <PostComments postId={post.id} comments={post.comments || []} queryKey={queryKey} />
        ) : null}

        {showPostModal ? (
          <PostModal
            post={post}
            open={showPostModal}
            onCancel={() => setShowPostModal(false)}
            queryKey={queryKey}
          />
        ) : null}
      </Col>
    </Row>
  );
};
