import { useKeycloak } from '@react-keycloak/web';
import { decodeJwt } from 'jose';

export const REACT_APP_KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

export const useHasPermission = () => {
  const { keycloak: { token } } = useKeycloak();
  const payload = decodeJwt(token);

  const hasPermission = roles => {
    const hasPermission = roles?.some(role => payload.resource_access?.[REACT_APP_KEYCLOAK_CLIENT_ID]?.roles.includes(role));
    if (hasPermission || !roles) return true;
    return false;
  };

  return hasPermission;
};
