import React from "react";
import {
  Col,
  Row,
  Spin,
  Grid,
  Space,
  Typography,
  Avatar,
  Statistic,
  List,
  notification,
} from "antd";
import { TrophyTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { reportsAPI } from "@api";
import dayjs from "dayjs";

const { Text } = Typography;
const { useBreakpoint } = Grid;

export const LeaderboardType = {
  TOP_RECEIVERS: "topReceivers",
  TOP_GIVERS: "topGivers",
  TOP_GIVERS_ADMIN: "topGiversAdmin"
}

export const LeaderboardPeriod = {
  ALL_TIME: "",
  LAST_30_DAYS: dayjs().subtract(30, "days").format("YYYY-MM-DD")
}

export const Leaderboard = ({ type, period = "", podium = false }) => {
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const { sm } = useBreakpoint();

  const { isLoading, data } = useQuery({
    queryKey: ["leaderboard", type, period],
    queryFn: async () => reportsAPI.getLeaderboard(type, period).then(({ data }) => ({
      top_three: data.slice(0, 3),
      rest: data.slice(3)
    })),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const ranking = [
    { background: "#FFF6C4", color: "#FFD700", order: sm ? 1 : 0 },
    { background: "#E1E1E1", color: "#C0C0C0", order: sm ? 0 : 1 },
    { background: "#FFECDB", color: "#cd7f32", order: 2 },
  ];

  return (
    <Spin
      tip="Loading"
      spinning={isLoading}
      style={{ width: "100%", textAlign: "center", padding: "60px 0" }}
    >
      <Row
        justify="center"
        align="middle"
        style={{ flexWrap: "nowrap" }}
        wrap={false}
      >
        <Col span={24}>
          <Row
            justify="space-around"
            align="bottom"
            style={{ width: "100%", margin: 0 }}
            gutter={[12, 12]}
          >
            {
              data && data?.top_three.map(({ username, first_name, last_name, profile_picture_url, total_amount }, index) => (
                <Space key={username} size={12} style={{ background: ranking[index].background, flexDirection: "column", borderRadius: 8, paddingTop: 12, paddingBottom: 12, width: "100%" }}>
                  <Row style={{ gap: "10px" }}>
                    <Avatar src={profile_picture_url} />
                    <TrophyTwoTone twoToneColor={ranking[index].color} style={{ background: "white", fontSize: 20, justifyContent: "center", borderRadius: "50%", height: 30, width: 30 }} />
                  </Row>
                  <Space direction="vertical" size={[10, 0]} style={{ textAlign: "center" }}>
                    <Text>{`${first_name} ${last_name}`}</Text>
                    <Statistic
                      valueStyle={{
                        color: "#4dbb72",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                      value={total_amount}
                      suffix={t("points")}
                    />
                  </Space>
                </Space>
              ))
            }
          </Row>
          <List
            itemLayout="horizontal"
            dataSource={data?.rest ?? []}
            renderItem={({
              first_name,
              last_name,
              profile_picture_url,
              total_amount,
            }) => (
              <List.Item style={{ columnGap: 10 }}>
                <List.Item.Meta
                  avatar={<Avatar src={profile_picture_url} />}
                  style={{ alignItems: "center" }}
                  description={
                    <Text level={5} style={{ marginBlock: 0 }}>
                      {`${first_name} ${last_name}`}
                      {
                        !podium && (
                          <Statistic
                            valueStyle={{
                              color: "#4dbb72",
                              fontSize: "14px",
                            }}
                            value={total_amount}
                            suffix={t("points")}
                          />
                        )
                      }
                    </Text>
                  }
                />
                {
                  podium && (
                    <Statistic
                      valueStyle={{
                        color: "#4dbb72",
                        fontSize: "14px",
                      }}
                      value={total_amount}
                      suffix={t("points")}
                    />
                  )
                }
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Spin>
  );
};
