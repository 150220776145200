import instance from "./api";

const prefix = 'reports'

export default {
  getLeaderboard: async (leaderboardType, period) => {
    const queryParams = period.length >= 1 ? { type: leaderboardType, from: period } : { type: leaderboardType };
    return instance.get(`/${prefix}/leaderboard`, { params: queryParams });
  },
  getBalance: async (params) => {
    return instance.get(`/${prefix}/balance`, { params });
  },
  getMetricsRewards(fromDate, toDate) {
    return instance.get(`${prefix}/rewards`, { params: { fromDate, toDate } })
  },
  getMetricsUsers(fromDate, toDate) {
    return instance.get(`${prefix}/users`, { params: { fromDate, toDate } })
  }
};