import React from "react";
import { MainHeader } from "./MainHeader";
import { Layout, Grid } from "antd";
import { MobileMenu } from "@components";

const { Content, Footer } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ children }) => {
  const { lg } = useBreakpoint();

  return (
    <Layout style={{ paddingBottom: lg ? 0 : 54 }}>
      <MainHeader />
      <Content
        style={{
          marginTop: "67px",
          padding: "16px",
          minHeight: "calc(100vh - 135px)",
        }}
      >
        {children}
      </Content>
      {lg ? null : <MobileMenu />}
      <Footer style={{ textAlign: "center" }}>ExoMindset ©2023</Footer>
    </Layout>
  );
};
