export const theme = {
  components: {
    Typography: {
      colorTextHeading: "#48545b",
    },
  },
  token: {
    colorPrimary: "#2966C6",
  },
};
