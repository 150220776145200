import React, { useState, useMemo } from "react";
import { Button, Tooltip, Typography, notification } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { postsAPI } from "@api";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const LikeButton = ({ postId, likes, likedByUser, queryKey = ["posts", "all"] }) => {
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const [loading, isLoading] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: toggleLikeMutation } = useMutation({
    mutationFn: () => {
      isLoading(true);
      return postsAPI.togglePostLike(postId);
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(queryKey, (prev) => ({
        ...prev,
        pages: prev.pages.map((page) => ({
          ...page,
          data: page.data.map((post) =>
            post.id === postId
              ? {
                ...post,
                likes: data.likes,
                likedByUser: data.likedByUser,
              }
              : post
          ),
        })),
      }));
      queryClient.invalidateQueries("posts");
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
    onSettled: () => isLoading(false),
  });

  const tooltipText = useMemo(
    () =>
      likes?.slice(0, 3).reduce(
        (prev, curr, i) => ({
          ...prev,
          [`user${i + 1}`]: `${curr.firstName} ${curr.lastName}`,
        }),
        { amount: likes?.length - 3 }
      ),
    [likes]
  );

  return (
    <Tooltip
      key="like"
      title={
        likes?.length
          ? t(
            `feed.post.likes.${likes.length > 3 ? 4 : likes.length}`,
            tooltipText
          )
          : null
      }
      overlayStyle={{
        maxWidth: 300,
        whiteSpace: "pre-wrap",
        textAlign: "center",
      }}
    >
      <Button
        type="link"
        icon={
          likedByUser ? (
            <HeartFilled style={{ fontSize: 20 }} />
          ) : (
            <HeartOutlined style={{ fontSize: 20 }} />
          )
        }
        block
        onClick={toggleLikeMutation}
        disabled={loading}
      >
        <Text
          color="black"
          style={{ fontSize: 14, fontWeight: "300" }}
        >{`${likes?.length}`}</Text>
      </Button>
    </Tooltip>
  );
};
