import instance from "./api";

export default {
  getRedemptions: async (params) => {
    return instance.get("/redemptions", { params });
  },
  updateRedemption: async (id, data) => {
    return instance.put(`/redemptions/${id}/status`, data);
  },
};
