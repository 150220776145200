import React, { useState, useEffect } from "react";
import { Modal, Form, Input, notification, ColorPicker } from "antd";
import { ColorFactory } from "antd/es/color-picker/color";
import { useTranslation } from "react-i18next";
import { categoriesAPI } from "@api";
import { useQueryClient, useMutation } from "@tanstack/react-query";

export const presetColors = [
  '#8BB8FF',
  '#A7A7A7',
  '#71FF9A',
  '#FF7ABE',
  '#FFB778',
];

export const CategoryModal = ({ category, open, onCancel }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: ({ id, values }) => {
      if (typeof values.color !== "string") values.color = values.color.toHexString();
      setConfirmLoading(true);
      if (category) {
        return categoriesAPI.updateCategory(id, values);
      } else {
        return categoriesAPI.createCategory(values);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["categories"]);
      form.resetFields();
      onCancel();
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
    onSettled: () => setConfirmLoading(false),
  });

  useEffect(() => {
    if (category) {
      form.setFieldsValue(category);
    }
  }, [category]);

  const language = i18n.language.substring(0, 2);

  return (
    <Modal
      title={category ? t("categoryTable.edit") : t("categoryTable.add")}
      centered
      open={open}
      onOk={() => form.submit()}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      okText={t("categoryTable.form.btnSubmit")}
      confirmLoading={confirmLoading}
    >
      <Form
        form={form}
        name="categoryForm"
        onFinish={(values) => mutate({ id: category?.id, values })}
        autoComplete="off"
        scrollToFirstError
        labelCol={{ span: language === "en" ? 5 : 8 }}
        wrapperCol={{ span: language === "en" ? 19 : 16 }}
      >
        <Form.Item
          label={t("categoryTable.name")}
          name="name"
          rules={[{ required: true, message: t("categoryTable.form.name") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("categoryTable.color")}
          name="color"
          initialValue={new ColorFactory("#FFF")}
          rules={[{ required: true, message: t("categoryTable.form.color") }]}
        >
          <ColorPicker
            disabledAlpha
            panelRender={(_, { components: { Presets } }) => (
              <Presets />
            )}
            presets={[
              {
                label: 'Colors',
                colors: presetColors,
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
