import instance from "./api";

export default {
  getClaims: async (params) => {
    return instance.get("/claims", { params });
  },
  updateClaim: async (id, data) => {
    return instance.put(`/claims/${id}/status`, data);
  },
};
