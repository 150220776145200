import React from "react";
import { Link, useMatch } from "react-router-dom";
import {
  Space,
  Avatar,
  Statistic,
  Button,
  Spin,
  notification,
  Typography,
} from "antd";
import { ArrowRightOutlined, ShoppingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { usersAPI } from "@api";

const { Text } = Typography;

export const BalanceCard = () => {
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const isMarketplacePage = useMatch("/marketplace");

  const { isLoading: walletLoading, data: wallet } = useQuery({
    queryKey: ["wallet"],
    queryFn: () => usersAPI.getMyWallet().then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  return (
    <Spin
      spinning={walletLoading}
      style={{ width: "100%", textAlign: "center", padding: "60px 0" }}
    >
      <Space align="center" size={8} style={{ "flexWrap": "wrap", "justifyContent": "center" }}>
        <Avatar
          size="large"
          icon={<ShoppingOutlined />}
          style={{ color: "#2966c6", background: "#eef5ff" }}
        />
        <Statistic
          title=<Text style={{ color: "#000", fontSize: 16, fontWeight: 600 }}>
            {
              isMarketplacePage
                ? t("marketplace.spendingBalance")
                : t("marketplace.availableSpendingBalance")
            }
          </Text>
          valueStyle={{
            color: "#2966c6",
            fontSize: isMarketplacePage ? "16px" : "18px",
            fontWeight: 600
          }}
          value={wallet?.rewardBalance}
          suffix={wallet?.hasInfiniteBudget ? "" : t("points")}
        />
      </Space>
      {!isMarketplacePage && (
        <Link to="/marketplace" style={{ display: "block" }}>
          <Button style={{ borderColor: "#000", color: "#000", fontWeight: 600, marginTop: "10px", height: 40, width: "100%", padding: "4px 0px" }}>
            {t("marketplace.goToMarketplace")}
            <ArrowRightOutlined style={{ paddingTop: "4px" }} />
          </Button>
        </Link>
      )}
    </Spin>
  );
};
