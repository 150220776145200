import React, { useState } from "react";
import { Col, Row, Typography, Table, Input, Select, notification, DatePicker, Space, Grid, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useDebounce } from "@hooks";
import { redemptionsAPI } from "@api";
import dayjs from "dayjs";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DateFormatMapping, OrderByDirection } from "@constants";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { useBreakpoint } = Grid;

const OrderByField = {
  date: 'date',
  user: 'user_name',
  prize: 'prize_name',
  status: 'status',
  closed_date: 'closed_date',
}

const RedeemStatus = {
  PENDING: 'PENDING',
  ON_DELIVERY: 'ON_DELIVERY',
  ON_HOLD: 'ON_HOLD',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
}

const RedeemsPage = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    status: null,
    orderByField: null,
    orderByDirection: null,
    date: null,
    closedDate: null,
  });
  const [searchRedeem, setSearchRedeem] = useState("");
  const debouncedSearch = useDebounce(searchRedeem, 300);
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const { lg } = useBreakpoint();

  const statusOptions = [
    { value: "PENDING", label: t("status.PENDING") },
    { value: "DELIVERED", label: t("status.DELIVERED") },
    { value: "CANCELLED", label: t("status.CANCELLED") },
    { value: "ON_DELIVERY", label: t("status.ON_DELIVERY") },
    { value: "ON_HOLD", label: t("status.ON_HOLD") },
  ];

  const { isLoading, data: redeems } = useQuery({
    queryKey: ["redeems", { ...params, search: debouncedSearch }],
    queryFn: () => redemptionsAPI.getRedemptions({ 
      ...params,
      date: params.date?.map(date => date.format('YYYY-MM-DD')),
      closedDate: params.closedDate?.map(date => date.format('YYYY-MM-DD')),
      search: debouncedSearch
    }).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate, isLoading: isUpdating } = useMutation({
    mutationFn: ({ id, status }) => {
      return redemptionsAPI.updateRedemption(id, { status });
    },
    onSuccess: ({ data }, variables) => {
      queryClient.setQueryData(["redeems", { ...params, search: debouncedSearch }], (prev) => {
        return {
          ...prev,
          data: prev.data.map((item) =>
            item.id === variables.id ? { ...item, status: data.status } : item
          )
        }
      });
      notification.success({
        message: t("redeemTable.notification.status.success.title"),
        description: t("redeemTable.notification.status.success.description"),
      });
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const language = i18n.language.substring(0, 2);

  const columns = [
    {
      key: "date",
      title: t("redeemTable.createdAt"),
      dataIndex: "createdAt",
      align: "center",
      width: 75,
      sorter: true,
      render: (date) => (date ? dayjs(date).locale(language).format("L") : "-"),
    },
    {
      key: "user",
      title: t("redeemTable.user"),
      dataIndex: "user",
      width: 200,
      sorter: true,
      render: (user) => `${user.firstName} ${user.lastName}`,
    },
    {
      key: "prize",
      title: t("redeemTable.prize"),
      dataIndex: "prize",
      width: 300,
      sorter: true,
      render: (prize) => prize.name,
    },
    {
      key: "status",
      title: t("redeemTable.status"),
      dataIndex: "status",
      align: "center",
      width: 50,
      sorter: true,
      render: (status, { id }) => !(status === RedeemStatus.DELIVERED || status === RedeemStatus.CANCELLED) ? (
        <Select
          defaultValue={status}
          onChange={(newStatus) => {
            mutate({ id, status: newStatus });
          }}
          style={{ width: "150px" }}
          options={statusOptions}
          disabled={isUpdating}
        />
      ) : (
        <Tag color={status === RedeemStatus.DELIVERED ? "success" : "error"}>
          {status === RedeemStatus.DELIVERED ? t("status.DELIVERED") : t("status.CANCELLED")}
        </Tag>
      ),
    },
    {
      key: "closed_date",
      title: t("redeemTable.closed"),
      dataIndex: "closed",
      align: "center",
      width: 75,
      sorter: true,
      render: (date) => (date ? dayjs(date).locale(language).format("L") : "-"),
    },
  ];

  return (
    <>
      <Row justify="center" align="middle" style={{ maxWidth: 1300, margin: "0px auto", padding: 10, background: "white" }}>
        <Col span={22}>
          <Typography.Title align="left" level={2} style={{ marginTop: 0 }}>
            {t("navbar.redeems")}
          </Typography.Title>
        </Col>
        <Col
          span={22}
          align="end"
          style={{
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            overflow: "hidden",
            width: "100%",
            padding: "16px 0px",
            display: "flex",
            justifyContent: lg ? "flex-start" :  "center",
            columnGap: "16px",
            rowGap: "10px",
            flexWrap: "wrap",
            background: "#fff",
          }}
        >
          <Space 
            direction="vertical"
            style={{
              flexGrow: 1,
              textAlign: "left",
            }}
          >
            <Typography.Text>{t("redeemTable.filter.search")}</Typography.Text>
            <Search
              onChange={(e) => setSearchRedeem(e.target.value)}
              style={{
                width: "100%",
              }}
              allowClear
              placeholder={t("redeemTable.filter.search")}
            />
          </Space>
          <Space 
            direction="vertical"
            style={{
              textAlign: "left",
              width: lg ? 200 : 280
            }}
          >
            <Typography.Text>{t("redeemTable.filter.status")}</Typography.Text>
            <Select
              onChange={(value) => setParams({
                ...params,
                status: value
              })}
              style={{
                width: "100%",
                maxWidth: lg ? 200 : 280,
              }}
              options={statusOptions}
              allowClear
              placeholder={t("redeemTable.filter.status")}
            />
          </Space>
          <Space 
            direction="vertical"
            style={{
              textAlign: "left"
            }}
          >
            <Typography.Text>{t("redeemTable.filter.date")}</Typography.Text>
            <RangePicker
              onChange={(dates) => setParams({
                ...params,
                date: dates,
              })}
              format={DateFormatMapping[language]}
            />
          </Space>
          <Space 
            direction="vertical"
            style={{
              textAlign: "left"
            }}
          >
            <Typography.Text>{t("redeemTable.filter.closed")}</Typography.Text>
            <RangePicker
              onChange={(dates) => setParams({
                ...params,
                closedDate: dates,
              })}
              format={DateFormatMapping[language]}
            />
          </Space>
        </Col>
        <Col
          span={22}
          align="center"
          style={{
            height: "100%",
            backgroundColor: "#fff",
          }}
        >
          <Table
            dataSource={redeems?.data ?? []}
            columns={columns}
            pagination={{
              current: params.page,
              pageSize: params.limit,
              total: redeems?.count,
              position: ["bottomCenter"],
              size: "default"
            }}
            rowKey={"id"}
            scroll={{ x: "max-content", y: "calc(100vh - 335px)" }}
            size="small"
            loading={isLoading}
            borderRadius={false}
            onChange={(pagination, filters, sorter) => {
              setParams({
                ...params,
                page: pagination.current,
                limit: pagination.pageSize,
                orderByField: OrderByField[sorter.columnKey],
                orderByDirection: OrderByDirection[sorter.order]
              })
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default RedeemsPage;
