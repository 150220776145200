import React from "react";
import { Alert, Result } from "antd";

const ForbiddenScreen = () => (
  <>
    <Alert
      style={{ margin: "20px auto", maxWidth: "800px" }}
      showIcon
      type="warning"
      message="Ups! Looks like you don't have permissions to access this page"
      description="Please contact your manager in order to request access"
    />
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
    />
  </>
);

export default ForbiddenScreen;
