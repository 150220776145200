import React from "react";
import {
  Card,
  Tabs,
  Typography,
  Row
} from "antd";
import { useTranslation } from "react-i18next";
import { Feed } from '@components';
import { FeedFilters } from 'components/Feed/Feed';
import './MyRewards.styles.css';

export const MyRewards = () => {

  const { t } = useTranslation("common");

  const items = [
    {
      key: "received",
      label: `${t("rewards.tabs.received")}`,
      children: <Feed filterBy={FeedFilters.RECIPIENT} queryKey={["posts", "received"]} />,
    },
    {
      key: "given",
      label: `${t("rewards.tabs.given")}`,
      children: <Feed filterBy={FeedFilters.USER} queryKey={["posts", "given"]} />,
    },
  ];

  return (
    <Row style={{ containerType: "inline-size" }}>
      <Card className="card">
        <Typography.Title
          level={3}
          style={{
            marginTop: 0,
            textAlign: "center"
          }}
        >
          {t("rewards.title")}
        </Typography.Title>
        <Tabs
          defaultActiveKey="received"
          type="card"
          centered
          items={items}
        />
      </Card>
    </Row>
  )
}
