import React, { useState } from "react";
import { Modal, Form, Result, Typography, Space, notification, Select, Spin, Alert } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import TextArea from "antd/es/input/TextArea";
import "./ClaimAwardModal.styles.css";
import { awardsAPI, usersAPI } from "@api";
import Title from "antd/es/skeleton/Title";
import { useDebounce } from "@hooks";

export const ClaimAwardModal = ({
  award,
  open,
  onCancel,
  setShowAwardModal,
  giveable,
  totalExistingAwards
}) => {
  const [userSearch, setUserSearch] = useState("");
  const debouncedUserSearch = useDebounce(userSearch, 300);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const queryClient = useQueryClient();
  const existingAwards = totalExistingAwards?.filter(existingAward => existingAward.award.id === award?.id).length;

  const { mutate: claimAward } = useMutation({
    mutationFn: ({ award, comments, recipientId }) => {
      if (recipientId) {
        return awardsAPI
          .giveAward({ awardId: award.id, comments, recipientId })
          .then(({ data }) => data)
      } else {
        return awardsAPI
          .claimAward({ awardId: award.id, comments })
          .then(({ data }) => data)
      }
    },
    onSuccess: (data, variables) => {
      setConfirmLoading(false);
      setShowAwardModal(false);
      Modal.success({
        icon: null,
        maskClosable: true,
        content: (
          <>
            <Result
              status="success"
              title={`${t("marketplace.claim.success")} ${variables.award.name}`}
              subTitle={t("marketplace.claim.successDescription")}
              extra={
                <Title align="center" level={3} style={{ margin: 0 }}>
                  {`${t("status.status")}: ${t(`status.${data.status}`)}`}
                </Title>
              }
            />
          </>
        ),
      });
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["usersOptions", debouncedUserSearch],
    queryFn: () =>
      usersAPI
        .searchUsers({
          search: debouncedUserSearch,
          limit: 10,
        })
        .then(({ data }) =>
          data.map((user) => ({
            label: user.fullName,
            value: user.id,
          }))
        ),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  return (
    <Modal
      title={giveable ? t("awardsClaim.give") : t("awardTable.claim")}
      centered
      open={open}
      onOk={() => form.submit()}
      onCancel={() => {
        queryClient.invalidateQueries(["totalExistingAwards"]);
        onCancel();
        form.resetFields();
      }}
      okText={giveable ? t("awardsClaim.give") : t("claimTable.claimButton")}
      confirmLoading={confirmLoading}
      maskClosable={true}
      className="awardModal"
    >
      <Form
        form={form}
        name="awardForm"
        autoComplete="off"
        scrollToFirstError
        onFinish={async (values) => {
          await claimAward({ award, comments: values.comments, recipientId: values.recipientId });
          form.resetFields();
        }}
      >
        <Space direction="vertical" size="large">
          <Typography>{t("claimTable.claimInstructions")}</Typography>
          <Form.Item name="comments">
            <TextArea
              size="large"
              placeholder={t("awardTable.comments")}
              rows={4}
              maxLength={100}
              autoSize={{ minRows: 1, maxRows: 3 }}
            />
          </Form.Item>
          {
            giveable && (
              <Form.Item
                name="recipientId"
                rules={[
                  {
                    required: true,
                    message: t("awardTable.form.recipient.required"),
                  }
                ]}
                style={{ margin: 0 }}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    border: "0.5px solid #aeaeae",
                    borderRadius: 6,
                  }}
                  placeholder={t("feed.form.recipient.placeholder")}
                  optionFilterProp="children"
                  onSearch={setUserSearch}
                  filterOption={(input, option) =>
                    option?.label
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={users}
                  allowClear
                  notFoundContent={
                    isFetchingUsers ? <Spin size="small" /> : null
                  }
                  bordered={false}
                />
              </Form.Item>
            )
          }
        </Space>
      </Form>
      {(!giveable && existingAwards > 0) && (
        <Alert message={t("awardsClaim.confirm.existingClaim", { count: existingAwards, existingAwards })} type="warning" showIcon style={{ marginTop: 18 }} />
      )}
    </Modal>
  );
};
