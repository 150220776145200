import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Switch,
  Button,
  Upload,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import { awardsAPI } from "@api";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export const AwardModal = ({ award, open, onCancel }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: ({ id, values }) => {
      setConfirmLoading(true);
      if (award) {
        return awardsAPI.updateAward(id, values);
      } else {
        return awardsAPI.createAward(values);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["awards"]);
      form.resetFields();
      onCancel();
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
    onSettled: () => setConfirmLoading(false),
  });

  useEffect(() => {
    if (award) {
      form.setFieldsValue({
        ...award,
      });
      setFile([
        {
          uid: award?.imageUrl,
          thumbUrl: award?.imageUrl,
          url: award?.imageUrl,
        },
      ]);
    }
  }, [award]);

  const language = i18n.language.substring(0, 2);

  return (
    <Modal
      title={award ? t("awardTable.edit") : t("awardTable.add")}
      centered
      open={open}
      onOk={() => form.submit()}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      okText={t("userTable.form.btnSubmit")}
      confirmLoading={confirmLoading}
      maskClosable={true}
    >
      <Form
        form={form}
        name="awardForm"
        onFinish={(values) => mutate({ id: award?.id, values })}
        autoComplete="off"
        scrollToFirstError
        labelCol={{ span: language === "en" ? 5 : 8 }}
        wrapperCol={{ span: language === "en" ? 19 : 16 }}
      >
        <Form.Item
          label={t("awardTable.name")}
          name="name"
          rules={[{ required: true, message: t("awardTable.form.name") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("awardTable.description")}
          name="description"
          rules={[
            { required: true, message: t("awardTable.form.description") },
          ]}
        >
          <TextArea rows={4} style={{ resize: "none" }} />
        </Form.Item>
        <Form.Item
          label={t("awardTable.amount")}
          name="amount"
          rules={[{ required: true, message: t("awardTable.form.amount") }]}
        >
          <InputNumber step={5} min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label={t("awardTable.available")}
          name="available"
          rules={[{ required: true, message: t("awardTable.form.available") }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={t("awardTable.imageUrl")}
          name="attachment"
          rules={[
            {
              required: !award,
              message: t("awardTable.form.imageUrl"),
            },
          ]}
          getValueFromEvent={({ file }) => file}
        >
          <Upload
            listType="picture"
            maxCount={1}
            accept="image/png, image/jpeg, image/jpg"
            beforeUpload={() => false}
            multiple={false}
            file={file}
            onChange={({ file }) => setFile(file)}
          >
            <Button icon={<UploadOutlined />}>
              {t("userTable.form.btnUpload")}
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
