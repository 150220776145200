import React, { useState } from "react";
import {
  Col,
  Row,
  Typography,
  Button,
  Modal,
  Table,
  Input,
  Space,
  notification,
  Statistic,
  Switch,
  Grid,
  Checkbox,
} from "antd";
import {
  EditOutlined,
  ExclamationCircleFilled,
  LockOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { UserModal } from "@components";
import { useDebounce } from "@hooks";
import { usersAPI, reportsAPI } from "@api";
import dayjs from "dayjs";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import "./Users.styles.css";
import { OrderByDirection } from "@constants";

const { confirm } = Modal;
const { Search } = Input;
const { useBreakpoint } = Grid;
const { Title, Paragraph } = Typography;

const OrderByField = {
  username: 'username',
  name: 'name',
  email: 'email',
  birthDate: 'birthDate',
  country: 'country',
  spendingBalance: 'spending_balance',
  giveableBalance: 'giveable_balance',
}

const UsersPage = () => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [searchUser, setSearchUser] = useState("");
  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    orderByField: null,
    orderByDirection: null,
    showSuperadminRole: true,
    includeInactive: false
  });
  const debouncedSearchUser = useDebounce(searchUser, 300);
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const language = i18n.language.substring(0, 2);
  const locales = language === "en" ? "en-US" : "es-AR";
  const { md, lg } = useBreakpoint();

  const { isLoading, data: users } = useQuery({
    queryKey: ["users", { ...params, search: debouncedSearchUser }],
    queryFn: () =>
      usersAPI
        .getUsers({ ...params, search: debouncedSearchUser })
        .then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { isLoading: isLoadingBalance, data: balance } = useQuery({
    queryKey: ["balance", { includeInactive: params.includeInactive }],
    queryFn: () =>
      reportsAPI.getBalance({ includeInactive: params.includeInactive }).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  })

  const { mutate: deactivateUserMutation } = useMutation({
    mutationFn: usersAPI.deactivateUser,
    onSuccess: (_, id) => {
      queryClient.setQueryData(["users", { ...params, search: debouncedSearchUser }], (prev) =>
        prev.map((item) => (item.id === id ? { ...item, active: false } : item))
      );
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate: resetUserPasswordMutation } = useMutation({
    mutationFn: usersAPI.resetPassword,
    onSuccess: (_, id) => {
      queryClient.setQueryData(["users", { ...params, search: debouncedSearchUser }], (prev) =>
        prev.map((item) => (item.id === id ? { ...item, active: false } : item))
      );
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate: activateUserMutation } = useMutation({
    mutationFn: usersAPI.activateUser,
    onSuccess: (_, id) => {
      queryClient.setQueryData(["users", { ...params, search: debouncedSearchUser }], (prev) =>
        prev.map((item) => (item.id === id ? { ...item, active: true } : item))
      );
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate: infiniteBudgetMutation } = useMutation({
    mutationFn: ({ id, hasInfiniteBudget }) => {
      return usersAPI.setInfiniteBudget(id, { hasInfiniteBudget });
    },
    onSuccess: ({ data }, { id }) => {
      queryClient.setQueryData(["users", { ...params, search: debouncedSearchUser }], (prev) =>
        prev.map((item) =>
          item.wallet.id === id ? { ...item, wallet: data } : item
        )
      );
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate: superadminMutation } = useMutation({
    mutationFn: ({ id }) => {
      return usersAPI.toggleSuperadmin(id);
    },
    onSuccess: ({ data: { id, superadmin } }) => {
      queryClient.setQueryData(["users", { ...params, search: debouncedSearchUser }], prev => {
        return {
          ...prev,
          data: prev?.data.map(user => user.id === id ? { ...user, superadmin } : user)
        }
      })
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const columns = [
    {
      key: "username",
      title: t("userTable.username"),
      dataIndex: "username",
      fixed: lg ? "left" : "",
      width: 300,
      sorter: true,
    },
    {
      key: "name",
      title: t("userTable.name"),
      dataIndex: "fullName",
      width: 300,
      sorter: true,
    },
    {
      key: "email",
      title: t("userTable.email"),
      dataIndex: "email",
      width: 300,
      sorter: true,
    },
    {
      key: "birthDate",
      title: t("userTable.birthDate"),
      dataIndex: "birthDate",
      align: "center",
      width: 110,
      render: (date) =>
        date ? dayjs(date)?.locale(language)?.format("L") : "-",
      sorter: true,
    },
    {
      key: "country",
      title: t("userTable.country"),
      dataIndex: ["country", "name"],
      width: 200,
      render: (name) => (name ? name[language] : "-"),
      sorter: true,
    },
    {
      key: "spendingBalance",
      title: t("marketplace.spendingBalance"),
      dataIndex: ["wallet", "rewardBalance"],
      align: "center",
      width: 90,
      render: (rewardBalance) => (
        <Statistic
          valueStyle={{ color: "#2966c6", fontSize: 14 }}
          value={rewardBalance === 0 ? "-" : rewardBalance}
        />
      ),
      sorter: true,
    },
    {
      key: "giveableBalance",
      title: t("marketplace.giveableBalance"),
      dataIndex: "wallet",
      align: "center",
      width: 90,
      render: ({ hasInfiniteBudget, allowanceBalance }) => (
        <Statistic
          valueStyle={{ color: "#4dbb72", fontSize: 14 }}
          value={hasInfiniteBudget ? t("unlimited") : allowanceBalance === 0 ? "-" : allowanceBalance}
        />
      ),
      sorter: true,
    },
    {
      title: t("userTable.infiniteBudget.label"),
      dataIndex: ["wallet", "hasInfiniteBudget"],
      width: 100,
      align: "center",
      render: (hasInfiniteBudget, { wallet: { id } }) => (
        <Switch
          checked={hasInfiniteBudget}
          onChange={() => showInfiniteBudgetConfirm(id, hasInfiniteBudget)}
        />
      ),
    },
    {
      title: t("userTable.superadmin.label"),
      dataIndex: ["superadmin"],
      width: 100,
      align: "center",
      render: (superadmin, { id }) => (
        <Switch
          checked={superadmin}
          onChange={() => showSuperadminConfirm(id, superadmin)}
        />
      ),
    },
    {
      title: t("userTable.actions"),
      dataIndex: "id",
      fixed: md ? "right" : "",
      align: "center",
      width: 220,
      render: (_, user) => (
        <Space>
          <Button
            onClick={() => {
              setCurrentUser(user);
              setShowUserModal(true);
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            onClick={() => {
              showResetUserPasswordConfirm(user);
            }}
          >
            <LockOutlined />
          </Button>
          {user.active ? (
            <Button onClick={() => showDeactivateUserConfirm(user)} danger>
              {t("userTable.disable.default")}
            </Button>
          ) : (
            <Button onClick={() => showActivateUserConfirm(user)} primary>
              {t("userTable.enable.default")}
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const showActivateUserConfirm = (user) => {
    confirm({
      title: t("userTable.enable.confirm"),
      icon: <ExclamationCircleFilled />,
      content: t("userTable.enable.description"),
      okType: "danger",
      okText: t("userTable.enable.ok"),
      cancelText: t("userTable.enable.cancel"),
      maskClosable: true,
      centered: true,
      onOk: () => activateUserMutation(user.id),
    });
  };

  const showDeactivateUserConfirm = (user) => {
    confirm({
      title: t("userTable.disable.confirm"),
      icon: <ExclamationCircleFilled />,
      content: t("userTable.disable.description"),
      okType: "danger",
      okText: t("userTable.disable.ok"),
      cancelText: t("userTable.disable.cancel"),
      maskClosable: true,
      centered: true,
      onOk: () => deactivateUserMutation(user.id),
    });
  };

  const showResetUserPasswordConfirm = (user) => {
    confirm({
      title: t("userTable.resetPassword.confirm"),
      icon: <ExclamationCircleFilled />,
      content: t("userTable.resetPassword.description"),
      okType: "danger",
      okText: t("userTable.resetPassword.ok"),
      cancelText: t("userTable.resetPassword.cancel"),
      maskClosable: true,
      centered: true,
      onOk: () => resetUserPasswordMutation(user.id),
    });
  };

  const showInfiniteBudgetConfirm = (id, hasInfiniteBudget) => {
    confirm({
      title: t("userTable.infiniteBudget.confirm", {
        action: t(`userTable.infiniteBudget.${hasInfiniteBudget}`),
      }),
      icon: <ExclamationCircleFilled />,
      okType: "danger",
      okText: t("userTable.infiniteBudget.ok", {
        action: t(`userTable.infiniteBudget.${hasInfiniteBudget}`),
      }),
      cancelText: t("userTable.infiniteBudget.cancel"),
      maskClosable: true,
      centered: true,
      onOk: () =>
        infiniteBudgetMutation({ id, hasInfiniteBudget: !hasInfiniteBudget }),
    });
  };

  const showSuperadminConfirm = (id, checked) => {
    confirm({
      title: t("userTable.superadmin.confirm", {
        action: t(`userTable.superadmin.${checked}`),
      }),
      content: !checked ? (
        <Paragraph style={{ margin: "10px 0" }}>
          {t("userTable.superadmin.warning")}
        </Paragraph>
      ) : null,
      icon: <ExclamationCircleFilled />,
      okType: "danger",
      okText: t("userTable.superadmin.ok", {
        action: t(`userTable.superadmin.${checked}`),
      }),
      cancelText: t("userTable.superadmin.cancel"),
      maskClosable: true,
      centered: true,
      onOk: () => superadminMutation({ id }),
    });
  };

  return (
    <>
      <Row justify="center" align="middle" style={{ padding: 10, background: "white" }}>
        <Col span={22}>
          <Title align="left" level={2} style={{ marginTop: 0 }}>
            {t("navbar.users")}
          </Title>
        </Col>
        <Col
          span={22}
          style={{
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            overflow: "hidden",
            width: "100%",
            padding: "16px 0px",
            display: "flex",
            justifyContent: lg ? "space-between" : "center",
            columnGap: "8px",
            rowGap: "20px",
            flexWrap: "wrap",
            background: "#fff",
          }}
        >
          <Space size={"large"} direction={lg ? "horizontal" : "vertical"} style={{ textAlign: "center" }}>
            <Statistic title={t("userTable.metric.spendingBalance")} value={!isLoadingBalance ? balance.totalSpending.toLocaleString(locales) : "Loading..."} style={{ marginLeft: 20 }} />
            <Statistic title={t("userTable.metric.giveableBalance")} value={!isLoadingBalance ? `${balance.giveableBalance.toLocaleString(locales)} / ${balance.monthlyGiveableBalance.toLocaleString(locales)} (${balance.percentageGiveableBalance}%)` : "Loading..."} />
            <Checkbox onChange={(e) => setParams({ ...params, includeInactive: e.target.checked })}>{t("userTable.form.includeInactive")}</Checkbox>
          </Space>
          <Space
            direction={lg ? "horizontal" : "vertical"}
            style={{ textAlign: "center", rowGap: "20px", width: "500px" }}
            className="search-space"
          >
            <Search
              onChange={(e) => setSearchUser(e.target.value)}
              style={{
                width: "100%",
              }}
              allowClear
              placeholder={t("userTable.form.search")}
            />
            <Button
              icon={<UserAddOutlined />}
              onClick={() => setShowUserModal(true)}
            >
              {t("userTable.add")}
            </Button>
          </Space>
        </Col>
        <Col span={22}>
          <Table
            dataSource={users?.data ?? []}
            columns={columns}
            pagination={{
              current: params.page,
              pageSize: params.limit,
              total: users?.count,
              position: ["bottomCenter"],
              size: "default"
            }}
            rowKey={"id"}
            scroll={{ x: "max-content", y: "calc(100vh - 335px)" }}
            size="small"
            loading={isLoading}
            borderRadius={false}
            onChange={(pagination, filters, sorter) => {
              setParams({
                ...params,
                page: pagination.current,
                limit: pagination.pageSize,
                orderByField: OrderByField[sorter.columnKey],
                orderByDirection: OrderByDirection[sorter.order]
              })
            }}
          />
        </Col>
      </Row>

      <UserModal
        user={currentUser}
        open={showUserModal}
        onCancel={() => {
          setShowUserModal(false);
          setCurrentUser(null);
        }}
      />
    </>
  );
};

export default UsersPage;
