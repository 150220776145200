import instance from "./api";

export default {
  getAwards: async (params) => {
    return instance.get("/awards", { params });
  },
  createAward: async (data) => {
    return instance.post("/awards", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateAward: async (id, data) => {
    return instance.put(`/awards/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteAward: async (id) => {
    return instance.delete(`/awards/${id}`);
  },
  claimAward: async (data) => {
    return instance.post("/awards/claim", data);
  },
  giveAward: async (data) => {
    return instance.post("/awards/give", data);
  },
};
