import React, { useState } from "react";
import {
  Col,
  Row,
  Typography,
  Button,
  Modal,
  Table,
  Input,
  Space,
  notification,
  Grid,
  ColorPicker,
} from "antd";
import {
  ExclamationCircleFilled,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { CategoryModal } from "@components";
import { useDebounce } from "@hooks";
import { categoriesAPI } from "@api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { OrderByDirection } from "@constants";

const { confirm } = Modal;
const { Search } = Input;
const { useBreakpoint } = Grid;

const OrderByField = {
  name: 'name',
}

const CategoriesPage = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    orderByField: null,
    orderByDirection: null,
  });
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [searchCategory, setSearchCategory] = useState("");
  const debouncedSearchCategory = useDebounce(searchCategory, 500);
  const queryClient = useQueryClient();
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const { md, lg } = useBreakpoint();

  const { isLoading, data: categories } = useQuery({
    queryKey: ["categories", { ...params, search: debouncedSearchCategory }],
    queryFn: () => categoriesAPI.getCategories({ ...params, search: debouncedSearchCategory }).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate: deleteCategoryMutation } = useMutation({
    mutationFn: categoriesAPI.deleteCategory,
    onSuccess: (data, id) => {
      queryClient.setQueryData(["categories", { ...params, search: debouncedSearchCategory }], (prev) =>
        prev.filter((item) => item.id !== id)
      );
    },
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const columns = [
    {
      key: "name",
      title: t("categoryTable.name"),
      dataIndex: "name",
      fixed: lg ? "left" : "",
      sorter: true,
    },
    {
      title: t("categoryTable.actions"),
      dataIndex: "id",
      fixed: md ? "right" : "",
      align: "center",
      width: 200,
      render: (_, category) => (
        <Space>
          <ColorPicker disabled value={category.color} />
          <Button
            onClick={() => {
              setCurrentCategory(category);
              setShowCategoryModal(true);
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            onClick={() => showDeactivateCategoryConfirm(category)}
            danger
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const showDeactivateCategoryConfirm = (category) => {
    confirm({
      title: t("categoryTable.delete.confirm"),
      icon: <ExclamationCircleFilled />,
      content: t("categoryTable.delete.description"),
      okType: "danger",
      okText: t("categoryTable.delete.ok"),
      cancelText: t("categoryTable.delete.cancel"),
      maskClosable: true,
      centered: true,
      onOk: () => deleteCategoryMutation(category.id),
    });
  };

  return (
    <>
      <Row justify="center" align="middle" style={{ maxWidth: 800, margin: "0px auto", padding: 10, background: "white" }}>
        <Col span={22}>
          <Typography.Title align="left" level={2} style={{ marginTop: 0 }}>
            {t("navbar.categories")}
          </Typography.Title>
        </Col>
        <Col
          span={22}
          align="end"
          style={{
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            overflow: "hidden",
            width: "100%",
            padding: "16px 0px",
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "8px",
            rowGap: "10px",
            flexWrap: "wrap",
            background: "#fff",
          }}
        >
          <Search
            onChange={(e) => setSearchCategory(e.target.value)}
            style={{ width: "100%", maxWidth: "300px" }}
            allowClear
            placeholder={t("categoryTable.search")}
          />
          <Button
            icon={<PlusOutlined />}
            onClick={() => setShowCategoryModal(true)}
          >
            {t("categoryTable.add")}
          </Button>
        </Col>
        <Col
          span={22}
          align="center"
          style={{
            height: "100%",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        >
          <Table
            dataSource={categories?.data ?? []}
            columns={columns}
            pagination={{
              current: params.page,
              pageSize: params.limit,
              total: categories?.count,
              position: ["bottomCenter"],
              size: "default"
            }}
            rowKey={"id"}
            scroll={{ x: "max-content", y: "calc(100vh - 335px)" }}
            size="small"
            loading={isLoading}
            borderRadius={false}
            onChange={(pagination, filters, sorter) => {
              setParams({
                ...params,
                page: pagination.current,
                limit: pagination.pageSize,
                orderByField: OrderByField[sorter.columnKey],
                orderByDirection: OrderByDirection[sorter.order]
              })
            }}
          />
        </Col>

        <CategoryModal
          category={currentCategory}
          open={showCategoryModal}
          onCancel={() => {
            setShowCategoryModal(false);
            setCurrentCategory(null);
          }}
        />
      </Row>
    </>
  );
};

export default CategoriesPage;
