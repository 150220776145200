import instance from "./api";

export default {
  getCategories: async (params) => {
    return instance.get("/posts/categories", { params });
  },
  createCategory: async (data) => {
    return instance.post(`/posts/categories`, data);
  },
  updateCategory: async (id, data) => {
    return instance.put(`/posts/categories/${id}`, data);
  },
  deleteCategory: async (id) => {
    return instance.delete(`/posts/categories/${id}`);
  },
};
