import React, { useState } from "react";
import {
  Col,
  Row,
  Typography,
  Button,
  Modal,
  Table,
  Input,
  Space,
  Image,
  notification,
  Grid,
  Tag,
  Select,
} from "antd";
import {
  ExclamationCircleFilled,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { PrizeModal } from "@components";
import { useDebounce } from "@hooks";
import { prizesAPI } from "@api";
import { fallbackImage } from "@assets";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import "./Prizes.styles.css";
import { OrderByDirection } from "@constants";

const { confirm } = Modal;
const { Search } = Input;
const { useBreakpoint } = Grid;

const OrderByField = {
  prizeName: 'prize_name',
  description: 'description',
  price: 'price',
  cost: 'cost',
  currencyName: 'currency_name',
  available: 'available',
  stockType: 'stockType',
  stockAmount: 'stockAmount',
  observation: 'observation',
}

const PrizesPage = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    orderByField: null,
    orderByDirection: null,
    available: null,
  });
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [currentPrize, setCurrentPrize] = useState(null);
  const [searchPrize, setSearchPrize] = useState("");
  const debouncedSearchPrize = useDebounce(searchPrize, 500);
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const { md, lg } = useBreakpoint();
  const language = i18n.language.substring(0, 2);
  const locales = language === "en" ? "en-US" : "es-AR";

  const { isLoading, data: prizes } = useQuery({
    queryKey: ["prizes", { ...params, search: debouncedSearchPrize }],
    queryFn: () => prizesAPI.getPrizes({ ...params, search: debouncedSearchPrize }).then(({ data }) => data),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const { mutate: deletePrizeMutation } = useMutation({
    mutationFn: prizesAPI.deletePrize,
    onSuccess: () => queryClient.invalidateQueries(["prizes"]),
    onError: ({ response }) =>
      notification.error({
        message: tError(response.data.message),
      }),
  });

  const columns = [
    {
      key: "prizeName",
      title: t("prizeTable.name"),
      dataIndex: "name",
      fixed: lg ? "left" : "",
      width: 300,
      sorter: true,
    },
    {
      key: "description",
      title: t("prizeTable.description"),
      dataIndex: "description",
      width: 300,
      sorter: true,
    },
    {
      key: "imageUrl",
      title: t("prizeTable.imageUrl"),
      align: "center",
      dataIndex: "imageUrl",
      width: 110,
      render: (_, prize) => (
        <Image
          width={50}
          height={50}
          src={prize.imageUrl}
          alt={prize.name}
          fallback={fallbackImage}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          wrapperStyle={{
            width: "50px",
            height: "50px",
            overflow: "hidden",
            borderRadius: "5px",
          }}
        />
      ),
    },
    {
      key: "price",
      title: t("prizeTable.price"),
      dataIndex: "price",
      align: "center",
      width: 75,
      sorter: true,
    },
    {
      key: "cost",
      title: t("prizeTable.cost"),
      dataIndex: "cost",
      align: "right",
      width: 75,
      sorter: true,
      render: (cost) => <Typography.Text>${cost.toLocaleString(locales)}</Typography.Text>,
    },
    {
      key: "currencyName",
      title: t("prizeTable.currency"),
      dataIndex: ["currency", "symbol"],
      align: "center",
      width: 80,
      sorter: true,
    },
    {
      key: "available",
      title: t("prizeTable.available"),
      dataIndex: "available",
      align: "center",
      width: 80,
      sorter: true,
      render: (_, { available }) =>
        available ? <Tag color="success">{t("prizeTable.available")}</Tag> : <Tag color="error">{t("prizeTable.unavailable")}</Tag>,
    },
    {
      key: "stockType",
      title: t("prizeTable.stockType"),
      dataIndex: "stockType",
      align: "center",
      width: 80,
      sorter: true,
    },
    {
      key: "stockAmount",
      title: t("prizeTable.stockAmount"),
      dataIndex: "stockAmount",
      align: "center",
      width: 80,
      sorter: true,
      render: (amount) => <Typography.Text>{amount === 0 ? "-" : amount}</Typography.Text>,
    },
    {
      key: "observation",
      title: t("prizeTable.observation"),
      dataIndex: "observation",
      width: 300,
      sorter: true,
    },
    {
      dataIndex: "id",
      title: t("prizeTable.actions"),
      fixed: md ? "right" : "",
      align: "center",
      width: 100,
      render: (_, prize) => (
        <Space>
          <Button
            onClick={() => {
              setCurrentPrize(prize);
              setShowPrizeModal(true);
            }}
          >
            <EditOutlined />
          </Button>
          <Button onClick={() => showDeactivatePrizeConfirm(prize)} danger>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const showDeactivatePrizeConfirm = (prize) => {
    confirm({
      title: t("prizeTable.delete.confirm"),
      icon: <ExclamationCircleFilled />,
      content: t("prizeTable.delete.description"),
      okType: "danger",
      okText: t("prizeTable.delete.ok"),
      cancelText: t("prizeTable.delete.cancel"),
      maskClosable: true,
      centered: true,
      onOk: () => deletePrizeMutation(prize.id),
    });
  };

  return (
    <>
      <Row justify="center" align="middle" style={{ padding: 10, background: "white" }}>
        <Col span={22}>
          <Typography.Title align="left" level={2} style={{ marginTop: 0 }}>
            {t("navbar.prizes")}
          </Typography.Title>
        </Col>
        <Col
          span={22}
          align="end"
          style={{
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            overflow: "hidden",
            width: "100%",
            padding: "16px 0px",
            display: "flex",
            justifyContent: md ? "space-between" : "center",
            columnGap: "8px",
            rowGap: "10px",
            flexWrap: "wrap",
            background: "#fff",
          }}
        >
          <Space 
            direction={md ? "horizontal" : "vertical"}
            style={{ textAlign: "center", width: md ? "500px" : "auto" }}
            className="search-space"
          >
            <Search
              onChange={(e) => setSearchPrize(e.target.value)}
              style={{ width: "100%", maxWidth: "300px" }}
              allowClear
              placeholder={t("prizeTable.form.search")}
            />
            <Select
              onChange={(value) => setParams({
                ...params,
                available: value
              })}
              style={{
                width: "200px",
                textAlign: "left"
              }}
              placeholder={t("prizeTable.available")}
              options={[
                {
                  value: true,
                  label: t("prizeTable.available")
                },
                {
                  value: false,
                  label: t("prizeTable.unavailable")
                }
              ]}
              allowClear
            />
          </Space>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setShowPrizeModal(true)}
          >
            {t("prizeTable.add")}
          </Button>
        </Col>
        <Col
          span={22}
          align="center"
          style={{
            height: "100%",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        >
          <Table
            dataSource={prizes?.data ?? []}
            columns={columns}
            pagination={{
              current: params.page,
              pageSize: params.limit,
              total: prizes?.count,
              position: ["bottomCenter"],
              size: "default"
            }}
            rowKey={"id"}
            scroll={{ x: "max-content", y: "calc(100vh - 335px)" }}
            size="small"
            loading={isLoading}
            borderRadius={false}
            onChange={(pagination, filters, sorter) => {
              setParams({
                ...params,
                page: pagination.current,
                limit: pagination.pageSize,
                orderByField: OrderByField[sorter.columnKey],
                orderByDirection: OrderByDirection[sorter.order]
              })
            }}
          />
        </Col>

        <PrizeModal
          prize={currentPrize}
          open={showPrizeModal}
          onCancel={() => {
            setShowPrizeModal(false);
            setCurrentPrize(null);
          }}
        />
      </Row>
    </>
  );
};

export default PrizesPage;
