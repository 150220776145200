import instance from "./api";

export default {
  getPrizes: async (params) => {
    return instance.get("/prizes", { params });
  },
  getPrizesBasic: async () => {
    return instance.get("/prizes/basic");
  },
  createPrize: async (data) => {
    return instance.post("/prizes", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updatePrize: async (id, data) => {
    return instance.put(`/prizes/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deletePrize: async (id) => {
    return instance.delete(`/prizes/${id}`);
  },
  redeemPrize: async (data) => {
    return instance.post("/prizes/redeem", data);
  },
  exchangePrize: async (data) => {
    return instance.post("/prizes/exchange", data);
  },
};
