import instance from "./api";

export default {
  getUsers: async (params) => {
    return instance.get("/users", { params });
  },
  getUser: async (id) => {
    return instance.get(`/users/${id}`);
  },
  createUser: async (data) => {
    return instance.post("/users", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateUser: async (id, data) => {
    return instance.put(`/users/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateMe: async (data) => {
    return instance.put(`/users/me`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  activateUser: async (id) => {
    return instance.put(`/users/${id}/activate`);
  },
  deactivateUser: async (id) => {
    return instance.put(`/users/${id}/deactivate`);
  },
  resetPassword: async (id) => {
    return instance.put(`/users/${id}/reset-password`);
  },
  deleteUser: async (id) => {
    return instance.delete(`/users/${id}`);
  },
  getMyInfo: async () => {
    return instance.get("/users/me");
  },
  getMyWallet: async () => {
    return instance.get("/users/me/wallet");
  },
  getMyRedemptions: async (params) => {
    return instance.get("/users/me/redemptions", { params });
  },
  getMyClaims: async (params) => {
    return instance.get("/users/me/claims", { params });
  },
  searchUsers: async (params) => {
    return instance.get("/users/search", { params });
  },
  setInfiniteBudget: async (id, data) => {
    return instance.put(`/users/${id}/infinite-budget`, data);
  },
  toggleSuperadmin: async (id) => {
    return instance.put(`/users/${id}/superadmin`);
  },
};
